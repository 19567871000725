import { useStore } from '@/store/store'

import type { UpdatePaymentMethodRequest } from '@/store/models/update-payment-method-request'

export class PaymentMethodDetails {
    /**
     * Stripe payment method ID.
     */
    stripePaymentMethodId?: string

    /**
     * Payment data formatted as python CapturePaymentRequest.
     */
    get updatePaymentMethodRequest(): UpdatePaymentMethodRequest {
        const store = useStore()

        return {
            billing_city: store.billingDetails.city,
            billing_company: store.billingDetails.company,
            billing_country: store.billingDetails.country,
            billing_email: store.billingDetails.email,
            billing_first_name: store.billingDetails.firstName,
            billing_last_name: store.billingDetails.lastName,
            billing_phone: store.billingDetails.phone,
            billing_postal_code: store.billingDetails.postalCode,
            billing_state: store.billingDetails.state,
            billing_street: store.billingDetails.address,
            is_mandate_accepted: store.isMandateAccepted,
            is_one_time_payment_method: false,
            stripe_payment_method_id: this.stripePaymentMethodId,
            user_agent: store.userAgent,
        }
    }
}
