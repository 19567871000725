import type { PaymentMethod } from '@/store/models/payment-method'

export class PaymentMethodConfiguration {
    /**
     * Payment method.
     */
    payment_method: PaymentMethod

    /**
     * Stripe account ID related to payment.
     */
    stripe_account_id: string

    /**
     * Stripe public key related to payment.
     */
    stripe_public_key: string

    /**
     * Initializes a new instance of the PaymentMethodConfiguration class.
     * @param paymentMethodConfiguration Payment configuration data.
     */
    constructor(paymentMethodConfiguration: Partial<PaymentMethodConfiguration>) {
        if (!paymentMethodConfiguration.payment_method) {
            throw 'Payment method information not sent by server.'
        }

        Object.assign(this, paymentMethodConfiguration)
    }
}
