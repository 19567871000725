<template>
    <div class="ut-billing-details-section">

        <!-- First Name input -->
        <ut-input
            v-model="billingDetails.firstName"
            data-testid="firstNameField"
            :errors="v$.billingDetails.firstName.$errors"
            :external-errors="store.externalErrorsForFirstName"
            label="First Name"
        />

        <!-- Last Name input -->
        <ut-input
            v-model="billingDetails.lastName"
            data-testid="lastNameField"
            :errors="v$.billingDetails.lastName.$errors"
            :external-errors="store.externalErrorsForLastName"
            label="Last Name"
        />

        <!-- Address input -->
        <ut-input
            v-model="billingDetails.address"
            data-testid="addressField"
            :errors="v$.billingDetails.address.$errors"
            :external-errors="store.externalErrorsForAddress"
            label="Address"
        />

        <!-- City input -->
        <ut-input
            v-model="billingDetails.city"
            data-testid="cityField"
            :errors="v$.billingDetails.city.$errors"
            :external-errors="store.externalErrorsForCity"
            label="City"
        />

        <!-- Country combobox -->
        <ut-combobox
            v-model="billingDetails.country"
            data-testid="countryField"
            :errors="v$.billingDetails.country.$errors"
            :external-errors="store.externalErrorsForCountry"
            label="Country"
            :options="countries"
        />

        <!-- State combobox -->
        <ut-combobox
            v-if="states"
            v-model="billingDetails.state"
            data-testid="stateField"
            :errors="v$.billingDetails.state.$errors"
            :external-errors="store.externalErrorsForState"
            label="State"
            :options="states"
        />

        <!-- State input -->
        <ut-input
            v-else
            v-model="billingDetails.state"
            data-testid="stateField"
            :errors="v$.billingDetails.state.$errors"
            :external-errors="store.externalErrorsForState"
            label="State"
        />

        <!-- Postal Code input -->
        <ut-input
            v-model="billingDetails.postalCode"
            data-testid="postalCodeField"
            :errors="v$.billingDetails.postalCode.$errors"
            :external-errors="store.externalErrorsForPostalCode"
            label="Postal Code"
        />

        <!-- Email input -->
        <ut-input
            v-model="billingDetails.email"
            data-testid="emailField"
            :errors="v$.billingDetails.email.$errors"
            :external-errors="store.externalErrorsForEmail"
            label="Email"
        />

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store/store'
import useValidate from '@vuelidate/core'
import UtInput from '@/components/ut-input/ut-input.vue'
import UtCombobox from '@/components/ut-combobox/ut-combobox.vue'
import { email, required } from '@vuelidate/validators'
import { COUNTRIES, STATES } from '@/constants'

import type { ComboboxOption } from '@/components/ut-combobox/combobox-option'

export default defineComponent({
    name: 'ut-billing-section',

    components: { UtCombobox, UtInput },

    data() {
        return {
            /**
             * Country options.
             */
            countries: COUNTRIES,

            /**
             * Vuelidate plugin.
             */
            v$: useValidate(),
        }
    },

    computed: {
        /**
         * Billing details.
         */
        billingDetails() {
            return useStore().billingDetails
        },

        /**
         * The state options given the current selected country.
         */
        states(): ComboboxOption[] | undefined {
            return (this.billingDetails.country) ? STATES[this.billingDetails.country] : undefined
        },

        /**
         * Application store.
         */
        store() {
            return useStore()
        },
    },

    watch: {
        /**
         * Resets the state input when country is changed.
         */
        country() {
            this.billingDetails.state = undefined
        },
    },

    methods: {
        /**
         * Validates the billing details form.
         */
        async validateAsync(): Promise<boolean> {
            return await this.v$.$validate()
        },
    },

    validations() {
        return {
            billingDetails: {
                address: { required },
                city: { required },
                country: { required },
                email: { email, required },
                firstName: { required },
                lastName: { required },
                postalCode: { required },
                state: { required },
            },
        }
    },
})
</script>

<style scoped lang="scss">

.ut-billing-details-section {
    display: grid;
    gap: .75rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

</style>
