import './assets/main.scss'

import '@stripe/stripe-js'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './app.vue'

import type { RuntimeConfiguration } from '@/store/configuration/runtime-configuration'

const stripeScript = document.createElement('script')
stripeScript.setAttribute('src', 'https://js.stripe.com/v3')
document.body.appendChild(stripeScript)

function uTerm(selector: string, options: RuntimeConfiguration): void {
    window.runtimeConfiguration = options
    const app = createApp(App)

    if (options.recaptchaV3SiteKey) {
        app.use(VueReCaptcha, { siteKey: options.recaptchaV3SiteKey ?? '', loaderOptions: {} })
    }

    app.use(createPinia())
    app.mount(selector)
}

window.uTerm = uTerm
