<template>
    <ut-form-element :label="label">

        <!-- Picklist -->
        <div class="ut-picklist">

            <!-- Input -->
            <div
                ref="input"
                class="ut-picklist-input"
                tabindex="0"
                v-bind="$attrs"
                @blur="handleBlurInput"
                @click="handleClickInput"
                @keydown.down.prevent
                @keydown.up.prevent
                @keyup.down="handleKeyDown"
                @keyup.enter.stop="handleKeyEnter"
                @keyup.esc="handleKeyEscape"
                @keyup.up="handleKeyUp"
            >

                <!-- Figure -->
                <ut-icon :name="selectedOption?.figure" class="ut-picklist-figure"/>

                <!-- Label -->
                <div class="ut-picklist-label">
                    {{ selectedOption?.label }}
                </div>

                <!-- Detail -->
                <div class="ut-picklist-detail">
                    {{ selectedOption?.detail }}
                </div>

            </div>

            <!-- Icon -->
            <ut-icon class="ut-picklist-icon" name="chevron-down" small/>

        </div>

        <!-- Dropdown -->
        <transition name="dropdown-transition">
            <div v-if="isOpen" class="ut-picklist-dropdown">
                <ul ref="options">
                    <ut-picklist-option
                        v-for="option in options"
                        :key="option.value"
                        :detail="option.detail"
                        :figure="option.figure"
                        :is-focused="option.value === focusedOption?.value"
                        :is-selected="option.value === selectedOption?.value"
                        :label="option.label"
                        :value="option.value"
                        @click="handleClickOption(option)"
                    />
                </ul>
            </div>
        </transition>

    </ut-form-element>
</template>

<script lang="ts">
import UtIcon from '@/components/ut-icon/ut-icon.vue'
import UtFormElement from '@/components/ut-form-element/ut-form-element.vue'
import UtPicklistOption from '@/components/ut-picklist/ut-picklist-option.vue'
import { defineComponent } from 'vue'
import { EVENTS } from '@/constants'

import type { PropType } from 'vue'
import type { PicklistOption } from '@/components/ut-picklist/picklist-option'
import type { ComboboxOption } from '@/components/ut-combobox/combobox-option'

export default defineComponent({
    name: 'ut-picklist',

    components: {
        UtIcon,
        UtFormElement,
        UtPicklistOption,
    },

    inheritAttrs: false,

    props: {
        /**
         * Input label.
         */
        label: String,

        /**
         * Input value.
         */
        modelValue: { type: String, required: false },

        /**
         * Options.
         */
        options: { type: Array as PropType<PicklistOption[]>, default: () => [] as PicklistOption[] },
    },

    data() {
        return {
            /**
             * Focused option value.
             */
            focusedOption: undefined as PicklistOption | undefined,

            /**
             * Indicates whether the dropdown is open.
             */
            isOpen: false,
        }
    },

    computed: {
        /**
         * The currently selected option, if any.
         */
        selectedOption(): PicklistOption | undefined {
            return this.modelValue && this.modelValue.length > 0
                ? this.options?.find(option => option.value === this.modelValue)
                : undefined
        },
    },

    methods: {
        /**
         * Clears the currently focused option.
         */
        clearFocusedOption(): void {
            this.focusedOption = undefined
        },

        /**
         * Focus on input element.
         */
        focusOnInput(): void {
            const focusableElement = this.$refs.input as HTMLElement
            focusableElement.focus()
        },

        /**
         * Ensures that the focused item is visible by scrolling the dropdown if necessary.
         */
        ensureFocusedItemVisibility() {
            if (this.focusedOption) {
                const optionsElement = this.$refs.options as HTMLElement
                const optionElement = optionsElement.querySelector(`li[data-value="${this.focusedOption.value}"]`)

                if (optionElement) {
                    optionElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'nearest',
                    })
                }
            }
        },

        /**
         * Handles the blue event on the input.
         */
        handleBlurInput() {
            if (this.selectedOption?.value !== this.modelValue) {
                this.$emit(EVENTS.UPDATE_MODEL_VALUE, this.selectedOption?.value)
            }

            this.hideDropdown()
        },

        /**
         * Handles the click event on the input.
         */
        handleClickInput(): void {
            if (!this.isOpen) {
                this.showDropdown()
            }
        },

        /**
         * Handles the click event on an option.
         * @param option The clicked option.
         */
        handleClickOption(option: PicklistOption): void {
            this.selectOption(option)
            this.focusOnInput()
        },

        /**
         * Handles the key down event on the combobox.
         */
        handleKeyDown(): void {
            if (!this.isOpen) {
                this.setFocusedOption()
                this.showDropdown()
            } else {
                this.setFocusedOptionDown()
            }

            this.focusOnInput()
        },

        /**
         * Handles the key enter event on the combobox.
         */
        handleKeyEnter(): void {
            if (!this.isOpen) {
                this.setFocusedOption()
                this.showDropdown()
            } else {
                this.selectOption(this.focusedOption!)
            }

            this.focusOnInput()
        },

        /**
         * Handles the key escape event on the combobox.
         */
        handleKeyEscape(): void {
            if (this.isOpen) {
                this.handleBlurInput()
            }

            this.focusOnInput()
        },

        /**
         * Handles the key up event on the combobox.
         */
        handleKeyUp(): void {
            if (!this.isOpen) {
                this.setFocusedOption()
                this.showDropdown()
            } else {
                this.setFocusedOptionUp()
            }

            this.focusOnInput()
        },

        /**
         * Hides the dropdown.
         */
        hideDropdown() {
            this.isOpen = false
            this.focusedOption = undefined
        },

        /**
         * Selects an option.
         * @param selectedOption Selected option.
         */
        selectOption(selectedOption: ComboboxOption): void {
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, selectedOption.value)
            if (selectedOption.value !== this.modelValue) this.$emit(EVENTS.CHANGE, selectedOption.value)

            this.hideDropdown()
            this.clearFocusedOption()
        },

        /**
         * Set the focused item.
         * @param focusedOption Hovered option, if any.
         */
        setFocusedOption(focusedOption?: PicklistOption): void {
            if (focusedOption) this.focusedOption = focusedOption
            else this.focusedOption = this.options[0]
        },

        /**
         * Sets the focused option as the one bellow the current one.
         */
        setFocusedOptionDown() {
            let index = (this.focusedOption)
                ? this.options.indexOf(this.options.find(option => option.value === this.focusedOption!.value)!)
                : -1

            do {
                index++
                if (index === this.options.length) index = 0
            } while (!this.options.some(option => option.value === this.options[index].value))

            this.focusedOption = this.options[index]
            this.ensureFocusedItemVisibility()
        },

        /**
         * Sets the focused option as the one above the current one.
         */
        setFocusedOptionUp() {
            let index = (this.focusedOption)
                ? this.options.indexOf(this.options.find(option => option.value === this.focusedOption!.value)!)
                : -1

            do {
                index--
                if (index === -1) index = this.options.length - 1
            } while (!this.options.some(option => option.value === this.options[index].value))

            this.focusedOption = this.options[index]
            this.ensureFocusedItemVisibility()
        },

        /**
         * Shows the dropdown.
         */
        showDropdown(): void {
            this.isOpen = true
            this.setFocusedOption()
        },
    },
})
</script>

<style scoped lang="scss">

.dropdown-transition {
    &-enter-active,
    &-leave-active {
        transition: transform .3s, opacity .15s !important;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0 !important;
        transform: translateY(-5%) !important;
    }
}

.ut-picklist {
    border-radius: 5px;
    position: relative;

    &-dropdown {
        background: white;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16);
        margin-top: .25rem;
        max-height: 12.5rem;
        overflow-y: auto;
        padding: .25rem 0;
        position: absolute;
        width: 100%;
        z-index: 9000;
    }

    &-icon {
        border: 0;
        line-height: 1;
        margin-top: -.25rem;
        pointer-events: none;
        position: absolute;
        right: .75rem;
        top: 50%;
        z-index: 2;
    }

    &-input {
        background-color: white;
        border: 1px solid #e6e6e6;
        border-radius: 0.25rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
        cursor: pointer;
        display: flex;
        padding: .75rem 2rem .75rem .75rem;
        transition-duration: 150ms;
        width: 100%;

        &:focus {
            border-color: hsla(210, 96%, 45%, 50%);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
            outline: 0;
        }
    }

    &-figure {
        margin-right: 1.5rem;
    }

    &-label {
        color: #30313D;
        flex-grow: 1;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
    }

    &-detail {
        color: #6D6E78;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
}

</style>
