import { useStore } from '@/store/store'

import type { CapturePaymentRequest } from '@/store/models/capture-payment-request'

export class PaymentDetails {
    /**
     * Payment method ID.
     */
    paymentMethodId?: string

    /**
     * Payment method type.
     */
    paymentMethodType: string

    /**
     * reCAPTCHA V3 Token
     */
    recaptchaV3Token?: string

    /**
     * Indicates whether to save the payment method.
     */
    savePaymentMethod?: boolean = true

    /**
     * Stripe payment method ID.
     */
    stripePaymentMethodId?: string

    /**
     * Payment data formatted as python CapturePaymentRequest.
     */
    get capturePaymentRequest(): CapturePaymentRequest {
        const store = useStore()

        return {
            billing_city: store.billingDetails.city,
            billing_company: store.billingDetails.company,
            billing_country: store.billingDetails.country,
            billing_email: store.billingDetails.email,
            billing_first_name: store.billingDetails.firstName,
            billing_last_name: store.billingDetails.lastName,
            billing_phone: store.billingDetails.phone,
            billing_postal_code: store.billingDetails.postalCode,
            billing_state: store.billingDetails.state,
            billing_street: store.billingDetails.address,
            create_one_time_payment_method: this.savePaymentMethod == undefined ? this.savePaymentMethod : !this.savePaymentMethod,
            is_mandate_accepted: store.isMandateAccepted,
            payment_method_id: this.paymentMethodId,
            recaptcha_token: this.recaptchaV3Token,
            stripe_payment_method_id: this.stripePaymentMethodId,
            stripe_payment_method_type: this.paymentMethodType,
            user_agent: store.userAgent,
        }
    }
}
