<template>
    <ut-form-element
        :label="label"
        :errors="errors"
        :external-errors="externalErrors"
        v-bind="formElementAttributes"
    >

        <!-- Combobox -->
        <div class="ut-combobox">

            <!-- Input -->
            <input
                ref="input"
                v-model="displayValue"
                class="ut-combobox-input"
                data-testid="input"
                :placeholder="placeholder"
                v-bind="inputAttributes"
                @blur="handleBlurInput"
                @input="handleInput"
                @click="handleClickInput"
                @keydown.down.prevent
                @keydown.up.prevent
                @keyup.down="handleKeyDown"
                @keyup.enter.stop="handleKeyEnter"
                @keyup.esc="handleKeyEscape"
                @keyup.up="handleKeyUp"
            >

            <!-- Icon -->
            <ut-icon class="ut-combobox-icon" name="chevron-down" small/>

        </div>

        <!-- Dropdown -->
        <transition name="dropdown-transition">
            <div v-if="isOpen" class="ut-combobox-dropdown">
                <ul ref="options">

                    <!-- Options -->
                    <template v-if="hasVisibleOptions">
                        <ut-combobox-option
                            v-for="option in visibleOptions"
                            :key="option.value"
                            :is-focused="option.value === focusedOption?.value"
                            :is-selected="option.value === selectedOption?.value"
                            :label="option.label"
                            :value="option.value"
                            @click="handleClickOption(option)"
                        />
                    </template>

                    <!-- Empty message -->
                    <li v-else class="ut-combobox-option__empty">
                        No options to display
                    </li>

                </ul>
            </div>
        </transition>

    </ut-form-element>
</template>

<script lang="ts">
import UtComboboxOption from '@/components/ut-combobox/ut-combobox-option.vue'
import UtFormElement from '@/components/ut-form-element/ut-form-element.vue'
import UtIcon from '@/components/ut-icon/ut-icon.vue'
import { defineComponent, type PropType } from 'vue'
import { type ComboboxOption } from '@/components/ut-combobox/combobox-option'
import { type ValidationError } from '@/components/ut-form-element/validation-error'
import { EVENTS } from '@/constants'
import type { ExternalError } from '@/components/ut-form-element/external-error'

export default defineComponent({
    name: 'ut-combobox',

    components: { UtComboboxOption, UtIcon, UtFormElement },

    inheritAttrs: false,

    props: {
        /**
         * Array of error objects from vuelidate.
         */
        errors: {
            type: Array as PropType<ValidationError[]>,
            default: () => [] as ValidationError[],
        },

        /**
         * Array of external error objects.
         */
        externalErrors: {
            type: Array as PropType<ExternalError[]>,
            default: () => [] as ExternalError[],
        },

        /**
         * Input label.
         */
        label: String,

        /**
         * Input value.
         */
        modelValue: { type: String, required: false },

        /**
         * Options.
         */
        options: { type: Array as PropType<ComboboxOption[]>, default: () => [] as ComboboxOption[] },

        /**
         * Input placeholder.
         */
        placeholder: String,
    },

    data() {
        return {
            /**
             * Input display value.
             * This property is needed because the input display value might not be the same as the input value.
             */
            displayValue: '',

            /**
             * Combobox filter.
             */
            filter: '',

            /**
             * Focused option value.
             */
            focusedOption: undefined as ComboboxOption | undefined,

            /**
             * Indicates whether the dropdown is open.
             */
            isOpen: false,
        }
    },

    computed: {
        /**
         * Bindable form element attributes.
         */
        formElementAttributes(): Record<string, unknown> {
            const attributes: Record<string, unknown> = {}

            for (const attribute in this.$attrs) {
                if (attribute.startsWith('data-') || attribute === 'class') {
                    attributes[attribute] = this.$attrs[attribute]
                }
            }

            return attributes
        },

        /**
         * Indicates whether there are options on this dropdown.
         */
        hasOptions(): boolean {
            return Boolean(this.options && this.options.length > 0)
        },

        /**
         * Indicates whether there are visible options on this dropdown.
         */
        hasVisibleOptions(): boolean {
            return Boolean(this.visibleOptions && this.visibleOptions.length > 0)
        },

        /**
         * Bindable input attributes.
         */
        inputAttributes(): Record<string, unknown> {
            const attributes: Record<string, unknown> = {}

            for (const attribute in this.$attrs) {
                if (!attribute.startsWith('data-') && attribute !== 'class') {
                    attributes[attribute] = this.$attrs[attribute]
                }
            }

            return attributes
        },

        /**
         * The currently selected option, if any.
         */
        selectedOption(): ComboboxOption | undefined {
            return this.modelValue && this.modelValue.length > 0
                ? this.options?.find(option => option.value === this.modelValue)
                : undefined
        },

        /**
         * Filtered visible options.
         */
        visibleOptions(): ComboboxOption[] {
            let visibleOptions

            if (!this.displayValue || this.displayValue.length === 0) visibleOptions = this.options || []
            else visibleOptions = this.options.filter(option => option.label.toLowerCase().includes(this.displayValue.toLowerCase()))

            return visibleOptions
        },
    },

    watch: {
        /**
         * Watches for changes in the display value.
         */
        displayValue() {
            this.setFocusedOption()
        },
    },

    created() {
        this.displayValue = this.options?.find(option => option.value === this.modelValue)?.label || ''
    },

    methods: {
        /**
         * Clears the currently focused option.
         */
        clearFocusedOption(): void {
            this.focusedOption = undefined
        },

        /**
         * Ensures that the focused item is visible by scrolling the dropdown if necessary.
         */
        ensureFocusedItemVisibility() {
            if (this.focusedOption) {
                const optionsElement = this.$refs.options as HTMLElement
                const optionElement = optionsElement.querySelector(`li[data-value="${this.focusedOption.value}"]`)

                if (optionElement) {
                    optionElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'nearest',
                    })
                }
            }
        },

        /**
         * Focus on input element.
         */
        focusOnInput(): void {
            const focusableElement = this.$refs.input as HTMLElement
            focusableElement.focus()
        },

        /**
         * Handles the blue event on the input.
         */
        handleBlurInput() {
            // We need to check if the currently typped value is already a valid value
            // We then fire an event with the found value, otherwise we default to the current value.
            const typedOption = this.options.find(option => option.label.toLowerCase() === this.displayValue.toLowerCase())
            const value = typedOption?.value || this.selectedOption?.value
            const label = typedOption?.label || this.selectedOption?.label || ''

            if (value !== this.modelValue) {
                this.$emit(EVENTS.UPDATE_MODEL_VALUE, value)
            }

            this.displayValue = label
            this.hideDropdown()
        },

        /**
         * Handles the click event on the input.
         */
        handleClickInput(): void {
            if (!this.isOpen) {
                this.showDropdown()
            }
        },

        /**
         * Handles the click event on an option.
         * @param option The clicked option.
         */
        handleClickOption(option: ComboboxOption): void {
            this.selectOption(option)
            this.focusOnInput()
        },

        /**
         * Hides the dropdown.
         */
        hideDropdown() {
            this.isOpen = false
            this.focusedOption = undefined
        },

        /**
         * Handles the input event on the input.
         */
        handleInput(): void {
            if (!this.isOpen) {
                this.isOpen = true
                this.setFocusedOption()
            }
        },

        /**
         * Handles the key down event on the combobox.
         */
        handleKeyDown(): void {
            if (!this.isOpen) {
                this.setFocusedOption()
                this.showDropdown()
            } else if (this.hasOptions) {
                this.setFocusedOptionDown()
            }

            this.focusOnInput()
        },

        /**
         * Handles the key enter event on the combobox.
         */
        handleKeyEnter(): void {
            if (!this.isOpen) {
                this.setFocusedOption()
                this.showDropdown()
            } else if (this.hasOptions && this.focusedOption) {
                this.selectOption(this.focusedOption)
            }

            this.focusOnInput()
        },

        /**
         * Handles the key escape event on the combobox.
         */
        handleKeyEscape(): void {
            if (this.isOpen) {
                this.handleBlurInput()
            }

            this.focusOnInput()
        },

        /**
         * Handles the key up event on the combobox.
         */
        handleKeyUp(): void {
            if (!this.isOpen) {
                this.setFocusedOption()
                this.showDropdown()
            } else if (this.hasOptions) {
                this.setFocusedOptionUp()
            }

            this.focusOnInput()
        },

        /**
         * Selects an option.
         * @param selectedOption Selected option.
         */
        selectOption(selectedOption: ComboboxOption): void {
            this.displayValue = selectedOption.label
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, selectedOption.value)
            if (selectedOption.value !== this.modelValue) this.$emit(EVENTS.CHANGE, selectedOption.value)

            this.hideDropdown()
            this.clearFocusedOption()
        },

        /**
         * Set the focused item.
         * @param focusedOption Hovered option, if any.
         */
        setFocusedOption(focusedOption?: ComboboxOption): void {
            if (!this.hasOptions) return

            if (focusedOption) this.focusedOption = focusedOption
            else this.focusedOption = this.options.filter(option => option.label.toLowerCase().includes(this.displayValue.toLowerCase()))[0]
        },

        /**
         * Sets the focused option as the one bellow the current one.
         */
        setFocusedOptionDown() {
            let index = (this.focusedOption)
                ? this.options.indexOf(this.options.find(option => option.value === this.focusedOption!.value)!)
                : -1

            do {
                index++
                if (index === this.options.length) index = 0
            } while (!this.visibleOptions.some(option => option.value === this.options[index].value))

            this.focusedOption = this.options[index]
            this.ensureFocusedItemVisibility()
        },

        /**
         * Sets the focused option as the one above the current one.
         */
        setFocusedOptionUp() {
            let index = (this.focusedOption)
                ? this.options.indexOf(this.options.find(option => option.value === this.focusedOption!.value)!)
                : -1

            do {
                index--
                if (index === -1) index = this.options.length - 1
            } while (!this.visibleOptions.some(option => option.value === this.options[index].value))

            this.focusedOption = this.options[index]
            this.ensureFocusedItemVisibility()
        },

        /**
         * Shows the dropdown.
         */
        showDropdown(): void {
            this.isOpen = true

            const inputElement = this.$refs.input as HTMLInputElement
            inputElement.select()

            this.setFocusedOption()
        },
    },
})
</script>

<style scoped lang="scss">

.dropdown-transition {
    &-enter-active,
    &-leave-active {
        transition: transform .3s, opacity .15s !important;
    }

    &-enter-from,
    &-leave-to {
        opacity: 0 !important;
        transform: translateY(-5%) !important;
    }
}

.ut-combobox {
    border-radius: 5px;
    margin-top: .25rem;
    position: relative;

    &-dropdown {
        background: white;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, .16);
        margin-top: .25rem;
        max-height: 12.5rem;
        overflow-y: auto;
        padding: .25rem 0;
        position: absolute;
        width: 100%;
        z-index: 9000;
    }

    &-icon {
        border: 0;
        line-height: 1;
        margin-top: -.25rem;
        pointer-events: none;
        position: absolute;
        right: .75rem;
        top: 50%;
        z-index: 2;
    }

    &-input {
        background-color: white;
        border: 1px solid #e6e6e6;
        border-radius: 0.25rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
        padding: .5rem 2rem .5rem .75rem;
        transition-duration: 150ms;
        width: 100%;

        &:focus {
            border-color: hsla(210, 96%, 45%, 50%);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
            outline: 0;
        }
    }

    &-option__empty {
        text-align: center;
        cursor: default;
        padding: .75rem;
    }
}

.ut-has-error {
    .ut-combobox {
        border-color: #df1b41;
        border-width: 2px;
    }
}

</style>
