<template>

    <!-- Stencil -->
    <ut-stencil-panel v-if="!isInitialized && !hasError"/>

    <!-- Error -->
    <ut-error-panel v-if="hasError"/>

    <!-- Universal terminal -->
    <transition v-else name="fade" appear>
        <div v-show="isInitialized" :class="terminalPanelClassNames" data-testid="terminalPanel">

            <!-- Amount input -->
            <ut-amount-section v-if="store.isInCapturePaymentMode"/>

            <!-- Stored payment method information -->
            <ut-radio-section
                v-if="store.hasStoredPaymentMethods"
                data-testid="storedPaymentMethodSection"
                label="Stored Payment Method"
                :selected="selectedSection === sections.storedPaymentMethod"
                @select="handleSelectRadioSection(sections.storedPaymentMethod)"
            >
                <ut-picklist
                    v-model="paymentDetails.paymentMethodId"
                    :options="store.storedPaymentMethodOptions"
                />
            </ut-radio-section>

            <!-- New payment method information -->
            <ut-radio-section
                :class="{'mt-6': store.isInAddPaymentMethodMode}"
                data-testid="newPaymentMethodSection"
                :selected="selectedSection === sections.newPaymentMethod"
                label="New Payment Method"
                @select="handleSelectRadioSection(sections.newPaymentMethod)"
            >
                <div class="grid grid-cols-1 gap-6">

                    <!-- Stripe payment element -->
                    <div id="stripe-payment-element">

                        <div id="payment-element"/>

                        <div id="error-message">
                            <p v-if="store.showStripeError" class="ut-error" data-testid="stripeError">
                                {{ store.stripeError }}
                            </p>
                        </div>

                    </div>

                    <!-- Save Payment Method checkbox -->
                    <ut-checkbox
                        v-if="store.hasContact"
                        v-model="paymentDetails.savePaymentMethod"
                        data-testid="savePaymentMethodCheckbox"
                        label="Save Payment Method"
                    />

                </div>
            </ut-radio-section>

            <!-- Billing information -->
            <ut-billing-details-section
                ref="billingDetailsSection"
                :class="billingDetailsClassNames"
            />

            <!-- Buttons -->
            <div class="ut-form-footer">

                <!-- Process payment button -->
                <ut-button
                    v-if="store.isInCapturePaymentMode"
                    data-testid="processPaymentButton"
                    :label="mainButtonLabel"
                    :show-spinner="isSubmitting"
                    @click.stop="processPayment"
                />

                <!-- Add payment method button  -->
                <ut-button
                    v-if="store.isInAddPaymentMethodMode"
                    data-testid="addPaymentMethodButton"
                    :label="mainButtonLabel"
                    :show-spinner="isSubmitting"
                    @click.stop="addPaymentMethod"
                />

            </div>

        </div>
    </transition>

    <!-- Mandate -->
    <ut-mandate-panel @accept="handleAcceptMandateAsync" @cancel="handleCancelMandate"/>

    <!-- Result -->
    <ut-result-panel/>

</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store/store'
import { PaymentDetails } from '@/store/details/payment-details'
import { PaymentMethodDetails } from '@/store/details/payment-method-details'
import { RuntimeConfiguration } from '@/store/configuration/runtime-configuration'
import UtAmountSection from '@/components/ut-amount-section/ut-amount-section.vue'
import UtBillingDetailsSection from '@/components/ut-billing-details-section/ut-billing-details-section.vue'
import UtButton from '@/components/ut-button/ut-button.vue'
import UtCheckbox from '@/components/ut-checkbox/ut-checkbox.vue'
import UtPicklist from '@/components/ut-picklist/ut-picklist.vue'
import UtRadioSection from '@/components/ut-radio-section/ut-radio-section.vue'
import UtResultPanel from '@/components/ut-result-panel/ut-result-panel.vue'
import UtStencilPanel from '@/components/ut-stencil-panel/ut-stencil-panel.vue'
import { LABELS, PAYMENT_METHOD_TYPE, STATUS } from '@/constants'

import type { StripePaymentElement, StripePaymentElementOptions } from '@stripe/stripe-js'
import UtMandatePanel from '@/components/ut-mandate-panel/ut-mandate-panel.vue'
import UtErrorPanel from '@/components/ut-error-panel/ut-error-panel.vue'

export default defineComponent({
    components: {
        UtErrorPanel,
        UtMandatePanel,
        UtAmountSection,
        UtBillingDetailsSection,
        UtButton,
        UtCheckbox,
        UtPicklist,
        UtRadioSection,
        UtResultPanel,
        UtStencilPanel,
    },

    data() {
        return {
            /**
             * Indicates whether this component has thrown an error.
             */
            hasError: false,

            /**
             * Indicates whether this component has been initialized.
             */
            isInitialized: false,

            /**
             * Indicates whether this payment is being submitted.
             */
            isSubmitting: false,

            /**
             * Details of payment to be captured.
             */
            paymentDetails: new PaymentDetails(),

            /**
             * Details of payment method to be created.
             */
            paymentMethodDetails: new PaymentMethodDetails(),

            /**
             * Stripe payment element.
             */
            paymentElement: undefined as StripePaymentElement | undefined,

            /**
             * Radio section options.
             */
            sections: {
                newPaymentMethod: 'newPaymentMethod',
                storedPaymentMethod: 'storedPaymentMethod',
            },

            /**
             * The currently selected radio section, if any.
             */
            selectedSection: undefined as string | undefined,
        }
    },

    computed: {
        /**
         * The CSS class names for the billing details section.
         */
        billingDetailsClassNames(): string {
            let classNames = 'mt-2'

            if (this.store.contactId) classNames = 'mt-6'

            return classNames
        },

        /**
         * Indicates whether the mandate acceptance is pending.
         */
        isMandateAcceptancePending(): boolean {
            return (this.paymentDetails.paymentMethodType === PAYMENT_METHOD_TYPE.US_BANK_ACCOUNT)
                ? !this.store.isMandateAccepted
                : false
        },

        /**
         * Main button label.
         */
        mainButtonLabel(): string {
            let label = ''

            if (this.isInitialized) {
                if (this.store.isInCapturePaymentMode) {
                    if (this.isSubmitting) label = LABELS.PROCESSING
                    else label = LABELS.PROCESS_PAYMENT
                } else if (this.store.isInAddPaymentMethodMode) {
                    if (this.isSubmitting) label = LABELS.ADDING
                    else label = LABELS.ADD_PAYMENT_METHOD
                }
            }

            return label
        },

        /**
         * Application store.
         */
        store() {
            return useStore()
        },

        /**
         * The CSS class names for the terminal panel element.
         */
        terminalPanelClassNames(): string {
            let classNames = 'ut-terminal-panel'

            // Visibility
            if (this.store.showResult) classNames += ' ut-invisible'

            return classNames
        },
    },

    async mounted() {
        try {
            if (!window.runtimeConfiguration) throw 'No options provided.'
            this.store.initializeConfiguration(new RuntimeConfiguration(window.runtimeConfiguration))

            // Retrieve operation configuration
            if (this.store.isInCapturePaymentMode) {
                await this.store.retrievePaymentConfigurationAsync()

                if (this.store.hasStoredPaymentMethods) {
                    this.selectedSection = this.sections.storedPaymentMethod
                    this.paymentDetails.paymentMethodId = this.store.storedPaymentMethods[0].id
                }
            } else if (this.store.isInAddPaymentMethodMode) {
                await this.store.retrievePaymentMethodConfigurationAsync()
            }

            // Set up Stripe.js and Elements to be used
            await this.store.loadStripeAsync()
            this.createPaymentElement()
        } catch (error) {
            console.error(error)
            this.hasError = true
        }
    },

    methods: {
        /**
         * Adds a payment method to a given contact.
         */
        async addPaymentMethod(): Promise<void> {
            try {
                if (this.isSubmitting) return
                this.isSubmitting = true

                // Validate form
                this.store.stripeError = undefined
                this.store.externalErrors = []
                const billingDetailsSection = this.$refs.billingDetailsSection as typeof UtBillingDetailsSection
                const isFormValid = await billingDetailsSection.validateAsync()
                const submitResult = await this.store.elements.submit()

                if (submitResult.error || !isFormValid) {
                    console.error(submitResult.error)
                    this.isSubmitting = false
                    return
                }

                // Handle mandate
                if (this.isMandateAcceptancePending) {
                    this.store.showMandate = true
                    return
                }

                // Create the PaymentMethod using the details collected by the Payment Element
                const stripePaymentMethod = await this.store.createPaymentMethodAsync()
                this.paymentMethodDetails.stripePaymentMethodId = stripePaymentMethod.id
                const result = await this.store.updatePaymentMethodAsync(this.paymentMethodDetails)

                // Parse result
                if (result.status === 200) {
                    this.store.status = STATUS.COMPLETED
                } else if (result.status === 400) {
                    this.store.externalErrors = result.data.errors
                    this.isSubmitting = false
                } else if (result.status === 402) {
                    this.store.stripeError = result.data.errors[0]?.message
                    this.isSubmitting = false
                }
            } catch (error) {
                console.error(error)
                this.hasError = true
                this.isSubmitting = false
            }
        },

        /**
         * Creates the payment element and attach listeners
         */
        createPaymentElement(): void {
            this.paymentElement = this.store.elements.create('payment', {
                fields: {
                    billingDetails: {
                        email: 'never',
                        address: {
                            postalCode: 'never',
                            country: 'never',
                        },
                    },
                },
            } as StripePaymentElementOptions)

            this.paymentElement?.mount('#payment-element')

            this.paymentElement?.on('change', event => {
                this.paymentDetails.paymentMethodType = event.value.type
            })

            this.paymentElement?.on('ready', () => {
                setTimeout(() => {
                    this.isInitialized = true
                }, 300)
            })
        },

        /**
         * Handles the accept event on the mandate modal.
         */
        async handleAcceptMandateAsync(): Promise<void> {
            this.isSubmitting = false
            this.store.isMandateAccepted = true
            this.store.userAgent = window.navigator.userAgent

            if (this.store.isInCapturePaymentMode) await this.processPayment()
            else await this.addPaymentMethod()
        },

        /**
         * Handles the cancel event on the mandate modal.
         */
        handleCancelMandate(): void {
            this.isSubmitting = false
            this.store.isMandateAccepted = false
            this.store.userAgent = undefined
        },

        /**
         * Handles the select event on a radio section.
         * @param sectionName The selected section name.
         */
        handleSelectRadioSection(sectionName: string): void {
            if (this.selectedSection !== sectionName) {
                this.selectedSection = sectionName
            }
            if (this.selectedSection === this.sections.storedPaymentMethod) {
                this.paymentDetails.savePaymentMethod = undefined
            } else {
                this.paymentDetails.savePaymentMethod = true
            }
        },

        /**
         * Process a payment.
         */
        async processPayment(): Promise<void> {
            try {
                if (this.isSubmitting) return
                this.isSubmitting = true

                // Validate reCAPTCHA
                if (this.store.runtimeConfiguration?.recaptchaV3SiteKey) {
                    await this.$recaptchaLoaded()
                    this.paymentDetails.recaptchaV3Token = await this.$recaptcha('login')
                }

                // Validate form
                this.store.stripeError = undefined
                this.store.externalErrors = []
                const billingDetailsSection = this.$refs.billingDetailsSection as typeof UtBillingDetailsSection
                const isFormValid = await billingDetailsSection.validateAsync()

                if (this.selectedSection === this.sections.storedPaymentMethod) {
                    if (!isFormValid) {
                        this.isSubmitting = false
                        return
                    }
                } else {
                    const submitResult = await this.store.elements.submit()

                    if (submitResult.error || !isFormValid) {
                        console.error(submitResult.error)
                        this.isSubmitting = false
                        return
                    }

                    // Handle mandate
                    if (this.isMandateAcceptancePending) {
                        this.store.showMandate = true
                        return
                    }

                    // Create the PaymentMethod using the details collected by the Payment Element
                    const stripePaymentMethod = await this.store.createPaymentMethodAsync()
                    this.paymentDetails.stripePaymentMethodId = stripePaymentMethod.id
                    this.paymentDetails.paymentMethodId = undefined
                }

                // Capture payment
                const result = await this.store.capturePaymentAsync(this.paymentDetails)

                // Parse result
                if (result.status === 200) {
                    this.store.status = result.data.status
                } else if (result.status === 400) {
                    this.store.externalErrors = result.data.errors
                    this.isSubmitting = false
                } else if (result.status === 402) {
                    this.store.stripeError = result.data.errors[0]?.message
                    this.isSubmitting = false
                }
            } catch (error) {
                console.error(error)
                this.hasError = true
                this.isSubmitting = false
            }
        },
    },
})
</script>

<style scoped lang="scss">

#stripe-payment-element {
    min-height: 120px;
}

.ut-terminal-panel {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
}

.ut-form-footer {
    padding: 1.5rem;
}

.ut-error {
    color: #df1b41;
    font-size: 0.93rem;
}

</style>
