<template>
    <ut-form-element
        :label="label"
        :errors="errors"
        :external-errors="externalErrors"
        v-bind="formElementAttributes"
    >
        <input
            class="ut-input"
            data-testid="input"
            :maxlength="maxlength"
            :placeholder="placeholder"
            :type="type"
            :value="modelValue"
            v-bind="inputAttributes"
            @input="handleInput"
        >
    </ut-form-element>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { type ValidationError } from '@/components/ut-form-element/validation-error'
import UtFormElement from '@/components/ut-form-element/ut-form-element.vue'
import { EVENTS } from '@/constants'
import type { ExternalError } from '@/components/ut-form-element/external-error'

export default defineComponent({
    name: 'ut-input',

    components: { UtFormElement },

    inheritAttrs: false,

    props: {
        /**
         * Array of error objects from vuelidate.
         */
        errors: {
            type: Array as PropType<ValidationError[]>,
            default: () => [] as ValidationError[],
        },

        /**
         * Array of external error objects.
         */
        externalErrors: {
            type: Array as PropType<ExternalError[]>,
            default: () => [] as ExternalError[],
        },

        /**
         * Input label.
         */
        label: String,

        /**
         * Input max length.
         */
        maxlength: { type: Number, required: false },

        /**
         * Input value.
         */
        modelValue: { type: String, required: false },

        /**
         * Input placeholder.
         */
        placeholder: String,

        /**
         * Input type. Defaults to text.
         */
        type: { type: String, default: 'text' },
    },

    computed: {
        /**
         * Bindable form element attributes.
         */
        formElementAttributes(): Record<string, unknown> {
            const attributes: Record<string, unknown> = {}

            for (const attribute in this.$attrs) {
                if (attribute.startsWith('data-') || attribute === 'class') {
                    attributes[attribute] = this.$attrs[attribute]
                }
            }

            return attributes
        },

        /**
         * Bindable input attributes.
         */
        inputAttributes(): Record<string, unknown> {
            const attributes: Record<string, unknown> = {}

            for (const attribute in this.$attrs) {
                if (!attribute.startsWith('data-') && attribute !== 'class') {
                    attributes[attribute] = this.$attrs[attribute]
                }
            }

            return attributes
        },
    },

    methods: {
        /**
         * Handles the input event on the input.
         * @param event The fired event.
         */
        handleInput(event: Event): void {
            const target = event.target as HTMLInputElement
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, target.value)
        },
    },
})
</script>

<style scoped lang="scss">

input:focus {
    outline: none;
}

.ut-input {
    border: 1px solid #e6e6e6;
    border-radius: 0.25rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
    margin-top: 0.25rem;
    padding: .5rem .75rem;
    transition-duration: 150ms;
    width: 100%;

    &:focus {
        border-color: hsla(210, 96%, 45%, 50%);
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
        outline: 0;
    }
}

.ut-has-error {
    .ut-input {
        border-width: 2px;
        border-color: #df1b41;
    }
}

</style>
