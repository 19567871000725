<template>
    <div class="ut-amount-input" :class="{'is-bare': !store.hasStoredPaymentMethods}">
        <label>

            <span>
                Amount
            </span>

            <input :value="store.normalizedAmount" type="text" disabled>

        </label>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store/store'

export default defineComponent({
    name: 'ut-amount-section',

    computed: {
        /**
         * Application store.
         */
        store() {
            return useStore()
        },
    },
})
</script>

<style scoped lang="scss">

.ut-amount-input {
    border-bottom: 1px solid #e6e6e6;
    padding: 1.5rem;

    &.is-bare {
        border-bottom: none;
    }

    input {
        border: 1px solid #e6e6e6;
        border-radius: .25rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
        display: block;
        font-size: 1.5rem;
        line-height: 2rem;
        margin-top: 0.25rem;
        text-align: center;
        width: 100%;
    }

    span {
        color: #30313d;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
        font-size: 0.93rem;
        line-height: 1.25rem;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
    }
}

</style>
