export class BillingDetails {
    /**
     * Billing line 1 address.
     */
    address?: string

    /**
     * Billing city.
     */
    city?: string

    /**
     * Company name.
     */
    company: string = 'Test Company'

    /**
     * Billing country.
     */
    country?: string = 'US'

    /**
     * Email address.
     */
    email?: string

    /**
     * First name.
     */
    firstName?: string

    /**
     * Last name.
     */
    lastName?: string

    /**
     * Phone number.
     */
    phone?: string = '(123) 456 7890'

    /**
     * Billing postal/zip code.
     */
    postalCode?: string

    /**
     * Billing state.
     */
    state?: string

    /**
     * Billing full name.
     */
    get name(): string {
        return `${this.firstName} ${this.lastName}`
    }
}
