import type { Payment } from '@/store/models/payment'
import type { PaymentMethod } from '@/store/models/payment-method'

export class PaymentConfiguration {
    /**
     * Payment.
     */
    payment: Payment

    /**
     * Stored payment methods related to payment's contact.
     */
    stored_payment_methods: Array<PaymentMethod>

    /**
     * Stripe account ID related to payment.
     */
    stripe_account_id: string

    /**
     * Stripe public key related to payment.
     */
    stripe_public_key: string

    /**
     * Initializes a new instance of the PaymentConfiguration class.
     * @param paymentConfiguration Payment configuration data.
     */
    constructor(paymentConfiguration: Partial<PaymentConfiguration>) {
        if (!paymentConfiguration.payment) {
            throw 'Payment information not sent by server.'
        }

        Object.assign(this, paymentConfiguration)
    }
}
