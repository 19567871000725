<template>
    <li class="ut-picklist-option" :class="optionClassNames" :data-value="value">

        <!-- Figure -->
        <ut-icon :name="figure" class="ut-picklist-option-figure"/>

        <!-- Label -->
        <div class="ut-picklist-option-label">
            {{ label }}
        </div>

        <!-- Detail -->
        <div class="ut-picklist-option-detail">
            {{ detail }}
        </div>

    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UtIcon from '@/components/ut-icon/ut-icon.vue'

export default defineComponent({
    name: 'ut-picklist-option',

    components: { UtIcon },

    props: {
        /**
         * Option detail.
         */
        detail: {
            type: String,
            required: true,
        },

        /**
         * Option figure.
         */
        figure: {
            type: String,
            required: true,
        },

        /**
         * Indicates whether this option is focused.
         */
        isFocused: Boolean,

        /**
         * Indicates whether this option is selected.
         * @type {boolean}
         */
        isSelected: Boolean,

        /**
         * Option label.
         */
        label: {
            type: String,
            required: true,
        },

        /**
         * Option value.
         */
        value: String,
    },

    computed: {
        /**
         * CSS class names for the option.
         */
        optionClassNames(): string {
            let classNames = ''

            // Focus
            if (this.isFocused) classNames += ' ut-has-focus'

            // Selected
            if (this.isSelected) classNames += ' ut-is-selected'

            return classNames
        },
    },
})
</script>

<style scoped lang="scss">

.ut-picklist-option {
    cursor: pointer;
    display: flex;
    padding: .75rem;

    &:hover {
        background: #f3f4f6;
        transition-duration: 150ms;
    }

    &-figure {
        margin-right: 1.5rem;
    }

    &-label {
        color: #30313D;
        flex-grow: 1;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
    }

    &-detail {
        color: #6D6E78;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
}

.ut-has-focus {
    background: #E5E7EB;
}

.ut-is-selected {
    background: rgb(224 242 254) !important;
    color: rgb(8 47 73);
}

</style>
