import { defineStore } from 'pinia'
import { loadStripe } from '@stripe/stripe-js'
import { apiClient, initializeApiClient } from '@/store/api-client'
import { BillingDetails } from '@/store/details/billing-details'
import { RuntimeConfiguration } from '@/store/configuration/runtime-configuration'
import { PaymentConfiguration } from '@/store/configuration/payment-configuration'
import { PaymentMethodConfiguration } from '@/store/configuration/payment-method-configuration'
import { CURRENCIES, ERROR_PARAMETER, RUNTIME_MODES, STATUS } from '@/constants'

import type { PicklistOption } from '@/components/ut-picklist/picklist-option'
import type { PaymentDetails } from '@/store/details/payment-details'
import type { PaymentMethodDetails } from '@/store/details/payment-method-details'
import type { PaymentMethod } from '@/store/models/payment-method'
import type {
    CheckoutLocale,
    CreatePaymentMethodData,
    Stripe,
    StripeElementLocale,
    StripeElements,
} from '@stripe/stripe-js'
import type { ExternalError } from '@/components/ut-form-element/external-error'

export const useStore = defineStore({
    id: 'store',

    state: () => ({
        /**
         * Payment amount.
         */
        amount: 0,

        /**
         * Billing details.
         */
        billingDetails: new BillingDetails,

        /**
         * Current contact ID.
         */
        contactId: undefined as string | undefined,

        /**
         * Stripe elements.
         */
        elements: {} as StripeElements,

        /**
         * Invalid request errors returned by API call.
         */
        externalErrors: [] as ExternalError[],

        /**
         * Indicates whether the mandate was accepted.
         */
        isMandateAccepted: false,

        /**
         * Locale.
         */
        locale: 'auto' as StripeElementLocale | CheckoutLocale,

        /**
         * Runtime mode.
         */
        mode: RUNTIME_MODES.DEV,

        /**
         * Optional payment ID.
         */
        paymentId: undefined as string | undefined,

        /**
         * Payment configuration.
         */
        paymentConfiguration: undefined as PaymentConfiguration | undefined,

        /**
         * Optional payment method ID.
         */
        paymentMethodId: undefined as string | undefined,

        /**
         * Payment method configuration.
         */
        paymentMethodConfiguration: undefined as PaymentMethodConfiguration | undefined,

        /**
         * Runtime configuration options.
         */
        runtimeConfiguration: {} as RuntimeConfiguration,

        /**
         * Indicates whether to show the mandate panel.
         */
        showMandate: false,

        /**
         * Result status.
         */
        status: STATUS.IN_PROCESS,

        /**
         * Stored payment methods.
         */
        storedPaymentMethods: [] as Array<PaymentMethod>,

        /**
         * Stripe object.
         */
        stripe: null as Stripe | null,

        /**
         * Stripe error that was received and must be shown to the user.
         */
        stripeError: undefined as string | undefined,

        /**
         * Stripe public key.
         */
        stripePublicKey: '',

        /**
         * The user agent of the browser from which the account representative accepted their service agreement.
         */
        userAgent: undefined as string | undefined,
    }),

    getters: {
        /**
         * Indicates whether this payment has an associated contact ID.
         */
        hasContact(state): boolean {
            return Boolean(state.contactId && state.contactId.length > 0)
        },

        /**
         * Indicates whether this payment has stored payment methods associated with it.
         */
        hasStoredPaymentMethods(state): boolean {
            return Boolean(state.storedPaymentMethods.length > 0)
        },

        /**
         * Indicates whether the universal terminal is in add payment method mode.
         */
        isInAddPaymentMethodMode(state): boolean {
            return Boolean(!state.paymentId)
        },

        /**
         * Indicates whether the universal terminal is in capture payment mode.
         */
        isInCapturePaymentMode(state): boolean {
            return Boolean(state.paymentId)
        },

        externalErrorsForAddress(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_STREET)
        },

        externalErrorsForCity(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_CITY)
        },

        externalErrorsForCountry(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_COUNTRY)
        },

        externalErrorsForEmail(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_EMAIL)
        },

        externalErrorsForFirstName(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_FIRST_NAME)
        },

        externalErrorsForLastName(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_LAST_NAME)
        },

        externalErrorsForPostalCode(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_POSTAL_CODE)
        },

        externalErrorsForState(state): ExternalError[] | undefined {
            return state?.externalErrors.filter((error: ExternalError) => error.param === ERROR_PARAMETER.BILLING_STATE)
        },

        /**
         * Payment normalized amount, not in cents.
         */
        normalizedAmount(state): number {
            return Number(state.amount / 100)
        },

        /**
         * Indicates whether to stripe error.
         */
        showStripeError(state): boolean {
            return Boolean(state.stripeError && state.stripeError.length > 0)
        },

        /**
         * Indicates whether to show the payment result.
         */
        showResult(state): boolean {
            return Boolean(state.status === STATUS.COMPLETED || state.status === STATUS.PENDING)
        },

        /**
         * Stored payment methods formatted as picklist options.
         */
        storedPaymentMethodOptions(state): Array<PicklistOption> {
            return state.storedPaymentMethods.map((storedPaymentMethod: PaymentMethod) => {
                let detail = ''
                if(storedPaymentMethod.exp_month){
                    detail = `${storedPaymentMethod.exp_month}/${storedPaymentMethod.exp_year}`
                }

                return {
                    detail: detail,
                    figure: storedPaymentMethod.card_type || 'bank',
                    label: storedPaymentMethod.name,
                    value: storedPaymentMethod.id,
                } as PicklistOption
            })
        },
    },

    actions: {
        /**
         * Captures a payment.
         * @param paymentDetails Details of payment to be captured.
         */
        async capturePaymentAsync(paymentDetails: PaymentDetails): Promise<any> {
            const url = `/v2/payments/${this.paymentId}/capture`
            const data = paymentDetails.capturePaymentRequest
            const response = await apiClient.put(url, data)

            if (response.status == 500) {
                throw new Error(`Failed to capture payment ${this.paymentId}`)
            }

            return response
        },

        /**
         * Creates a new payment method record in Stripe.
         */
        async createPaymentMethodAsync() {
            const result = await this.stripe?.createPaymentMethod({
                elements: this.elements,
                params: {
                    billing_details: {
                        name: this.billingDetails.name,
                        email: this.billingDetails.email,
                        address: {
                            line1: this.billingDetails.address,
                            city: this.billingDetails.city,
                            country: this.billingDetails.country,
                            postal_code: this.billingDetails.postalCode,
                            state: this.billingDetails.state,
                        },
                    },
                },
            } as unknown as CreatePaymentMethodData)

            if (result?.error || !result || !result.paymentMethod) {
                // This point is only reached if there's an immediate error when
                // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
                throw result?.error || 'Payment method could not be created.'
            }

            return result.paymentMethod
        },

        /**
         * Initializes the store with the provided runtime configuration.
         * @param runtimeConfiguration
         */
        initializeConfiguration(runtimeConfiguration: RuntimeConfiguration): void {
            this.runtimeConfiguration = runtimeConfiguration
            this.locale = runtimeConfiguration.locale
            this.mode = runtimeConfiguration.mode
            this.paymentId = runtimeConfiguration.paymentId
            this.paymentMethodId = runtimeConfiguration.paymentMethodId
            initializeApiClient()
        },

        /**
         * Loads Stripe object and create an Elements instance.
         */
        async loadStripeAsync(): Promise<void> {
            this.stripe = await loadStripe(this.stripePublicKey, {
                apiVersion: '2020-08-27;us_bank_account_beta=v2',
                locale: this.locale,
            })

            if (!this.stripe) {
                throw 'Stripe failed to load.'
            }

            if (this.isInCapturePaymentMode) {
                this.elements = this.stripe.elements({
                    mode: 'payment',
                    currency: CURRENCIES.USD,
                    paymentMethodCreation: 'manual',
                    amount: this.amount,
                    appearance: this.runtimeConfiguration.appearance,
                }) as StripeElements
            } else if (this.isInAddPaymentMethodMode) {
                this.elements = this.stripe?.elements({
                    mode: 'setup',
                    appearance: this.runtimeConfiguration.appearance,
                    currency: CURRENCIES.USD,
                    paymentMethodCreation: 'manual',
                }) as StripeElements
            }
        },

        /**
         * Retrieves the initial configuration for a specific payment.
         */
        async retrievePaymentConfigurationAsync(): Promise<void> {
            if (!this.paymentId || this.paymentId.length === 0) {
                throw new Error('Configuration for payment is missing paymentId')
            }

            const url = `/v2/uterminal/payments/${this.paymentId}/config`
            const response = await apiClient.get(url)

            if (response.status !== 200) {
                throw new Error(`Failed to load UTerminal configuration for payment ${this.paymentId}`)
            }

            this.paymentConfiguration = new PaymentConfiguration(response.data)
            this.stripePublicKey = this.paymentConfiguration.stripe_public_key
            this.storedPaymentMethods = this.paymentConfiguration.stored_payment_methods
            this.amount = this.paymentConfiguration.payment.amount
            this.contactId = this.paymentConfiguration.payment.contact_id
        },

        /**
         * Gets the initial configuration for a specific payment method.
         */
        async retrievePaymentMethodConfigurationAsync(): Promise<void> {
            if (!this.paymentMethodId || this.paymentMethodId.length === 0) {
                throw new Error('Configuration for payment method is missing paymentMethodId')
            }

            const url = `/v2/uterminal/payment_methods/${this.paymentMethodId}/config`
            const response = await apiClient.get(url)

            if (response.status !== 200) {
                throw new Error(`Failed to load UTerminal configuration for payment method ${this.paymentMethodId}`)
            }

            this.paymentMethodConfiguration = new PaymentMethodConfiguration(response.data)
            this.stripePublicKey = this.paymentMethodConfiguration.stripe_public_key
        },

        /**
         * Updates a payment method.
         * @param paymentMethodDetails Details of payment method to be updated.
         */
        async updatePaymentMethodAsync(paymentMethodDetails: PaymentMethodDetails): Promise<any> {
            const url = `/v2/payment_methods/${this.paymentMethodId}`
            const data = paymentMethodDetails.updatePaymentMethodRequest
            const response = await apiClient.put(url, data)

            if (response.status == 500) {
                throw new Error(`Failed to update payment method ${this.paymentMethodId}`)
            }

            return response
        },
    },
})
