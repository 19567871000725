import {type ComboboxOption} from '@/components/ut-combobox/combobox-option'

type StatesType = {
    [key: string]: ComboboxOption[];
};

export const STATES: StatesType = {
    'AU': [
        { value: 'ACT', label: 'Australian Capital Territory' },
        { value: 'NSW', label: 'New South Wales' },
        { value: 'NT', label: 'Northern Territory' },
        { value: 'QLD', label: 'Queensland' },
        { value: 'SA', label: 'South Australia' },
        { value: 'TAS', label: 'Tasmania' },
        { value: 'VIC', label: 'Victoria' },
        { value: 'WA', label: 'Western Australia' },
    ] as ComboboxOption[],
    'BR': [
        { value: 'AC', label: 'Acre' },
        { value: 'AL', label: 'Alagoas' },
        { value: 'AP', label: 'Amapá' },
        { value: 'AM', label: 'Amazonas' },
        { value: 'BA', label: 'Bahia' },
        { value: 'CE', label: 'Ceará' },
        { value: 'DF', label: 'Distrito Federal' },
        { value: 'ES', label: 'Espírito Santo' },
        { value: 'GO', label: 'Goiás' },
        { value: 'MA', label: 'Maranhão' },
        { value: 'MT', label: 'Mato Grosso' },
        { value: 'MS', label: 'Mato Grosso do Sul' },
        { value: 'MG', label: 'Minas Gerais' },
        { value: 'PA', label: 'Pará' },
        { value: 'PB', label: 'Paraíba' },
        { value: 'PR', label: 'Paraná' },
        { value: 'PE', label: 'Pernambuco' },
        { value: 'PI', label: 'Piauí' },
        { value: 'RJ', label: 'Rio de Janeiro' },
        { value: 'RN', label: 'Rio Grande do Norte' },
        { value: 'RS', label: 'Rio Grande do Sul' },
        { value: 'RO', label: 'Rondônia' },
        { value: 'RR', label: 'Roraima' },
        { value: 'SC', label: 'Santa Catarina' },
        { value: 'SP', label: 'São Paulo' },
        { value: 'SE', label: 'Sergipe' },
        { value: 'TO', label: 'Tocantins' },
    ] as ComboboxOption[],
    'CA': [
        { value: 'AB', label: 'Alberta' },
        { value: 'BC', label: 'British Columbia' },
        { value: 'MB', label: 'Manitoba' },
        { value: 'NB', label: 'New Brunswick' },
        { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NT', label: 'Northwest Territories' },
        { value: 'NS', label: 'Nova Scotia' },
        { value: 'NU', label: 'Nunavut' },
        { value: 'ON', label: 'Ontario' },
        { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' },
        { value: 'SK', label: 'Saskatchewan' },
        { value: 'YT', label: 'Yukon Territories' },
    ] as ComboboxOption[],
    'CN': [
        { value: '34', label: 'Anhui' },
        { value: '11', label: 'Beijing' },
        { value: '50', label: 'Chongqing' },
        { value: '35', label: 'Fujian' },
        { value: '62', label: 'Gansu' },
        { value: '44', label: 'Guangdong' },
        { value: '45', label: 'Guangxi' },
        { value: '52', label: 'Guizhou' },
        { value: '46', label: 'Hainan' },
        { value: '13', label: 'Hebei' },
        { value: '23', label: 'Heilongjiang' },
        { value: '41', label: 'Henan' },
        { value: '81', label: 'Hong Kong' },
        { value: '42', label: 'Hubei' },
        { value: '43', label: 'Hunan' },
        { value: '32', label: 'Jiangsu' },
        { value: '36', label: 'Jiangxi' },
        { value: '22', label: 'Jilin' },
        { value: '21', label: 'Liaoning' },
        { value: '82', label: 'Macao' },
        { value: '15', label: 'Nei Mongol' },
        { value: '64', label: 'Ningxia' },
        { value: '63', label: 'Qinghai' },
        { value: '61', label: 'Shaanxi' },
        { value: '37', label: 'Shandong' },
        { value: '31', label: 'Shanghai' },
        { value: '14', label: 'Shanxi' },
        { value: '51', label: 'Sichuan' },
        { value: '71', label: 'Taiwan' },
        { value: '12', label: 'Tianjin' },
        { value: '65', label: 'Xinjiang' },
        { value: '54', label: 'Xizang' },
        { value: '53', label: 'Yunnan' },
        { value: '33', label: 'Zhejiang' },
    ] as ComboboxOption[],
    'IR': [
        { value: 'CW', label: 'Carlow' },
        { value: 'CN', label: 'Cavan' },
        { value: 'CE', label: 'Clare' },
        { value: 'CO', label: 'Cork' },
        { value: 'DL', label: 'Donegal' },
        { value: 'D', label: 'Dublin' },
        { value: 'G', label: 'Galway' },
        { value: 'KY', label: 'Kerry' },
        { value: 'KE', label: 'Kildare' },
        { value: 'KK', label: 'Kilkenny' },
        { value: 'LS', label: 'Laois' },
        { value: 'LM', label: 'Leitrim' },
        { value: 'LK', label: 'Limerick' },
        { value: 'LD', label: 'Longford' },
        { value: 'LH', label: 'Louth' },
        { value: 'MO', label: 'Mayo' },
        { value: 'MH', label: 'Meath' },
        { value: 'MN', label: 'Monaghan' },
        { value: 'OY', label: 'Offaly' },
        { value: 'RN', label: 'Roscommon' },
        { value: 'SO', label: 'Sligo' },
        { value: 'TA', label: 'Tipperary' },
        { value: 'WD', label: 'Waterford' },
        { value: 'WH', label: 'Westmeath' },
        { value: 'WX', label: 'Wexford' },
        { value: 'WW', label: 'Wicklow' },
    ] as ComboboxOption[],
    'IN': [
        { value: 'AN', label: 'Andaman and Nicobar Islands' },
        { value: 'AP', label: 'Andhra Pradesh' },
        { value: 'AR', label: 'Arunachal Pradesh' },
        { value: 'AS', label: 'Assam' },
        { value: 'BR', label: 'Bihar' },
        { value: 'CH', label: 'Chandigarh' },
        { value: 'CT', label: 'Chhattisgarh' },
        { value: 'DN', label: 'Dadra and Nagar Haveli' },
        { value: 'DD', label: 'Daman and Diu' },
        { value: 'DL', label: 'Delhi' },
        { value: 'GA', label: 'Goa' },
        { value: 'GJ', label: 'Gujarat' },
        { value: 'HR', label: 'Haryana' },
        { value: 'HP', label: 'Himachal Pradesh' },
        { value: 'JK', label: 'Jammu and Kashmir' },
        { value: 'JH', label: 'Jharkhand' },
        { value: 'KA', label: 'Karnataka' },
        { value: 'KL', label: 'Kerala' },
        { value: 'LD', label: 'Lakshadweep' },
        { value: 'MP', label: 'Madhya Pradesh' },
        { value: 'MH', label: 'Maharashtra' },
        { value: 'MN', label: 'Manipur' },
        { value: 'ML', label: 'Meghalaya' },
        { value: 'MZ', label: 'Mizoram' },
        { value: 'NL', label: 'Nagaland' },
        { value: 'OR', label: 'Odisha' },
        { value: 'PY', label: 'Puducherry' },
        { value: 'PB', label: 'Punjab' },
        { value: 'RJ', label: 'Rajasthan' },
        { value: 'SK', label: 'Sikkim' },
        { value: 'TN', label: 'Tamil Nadu' },
        { value: 'TR', label: 'Tripura' },
        { value: 'UP', label: 'Uttar Pradesh' },
        { value: 'UT', label: 'Uttarakhand' },
        { value: 'WB', label: 'West Bengal' },
    ] as ComboboxOption[],
    'IT': [
        { value: 'AG', label: 'Agrigento' },
        { value: 'AL', label: 'Alessandria' },
        { value: 'AN', label: 'Ancona' },
        { value: 'AO', label: 'Aosta' },
        { value: 'AR', label: 'Arezzo' },
        { value: 'AP', label: 'Ascoli Piceno' },
        { value: 'AT', label: 'Asti' },
        { value: 'AV', label: 'Avellino' },
        { value: 'BA', label: 'Bari' },
        { value: 'BT', label: 'Barletta-Andria-Trani' },
        { value: 'BL', label: 'Belluno' },
        { value: 'BN', label: 'Benevento' },
        { value: 'BG', label: 'Bergamo' },
        { value: 'BI', label: 'Biella' },
        { value: 'BO', label: 'Bologna' },
        { value: 'BZ', label: 'Bolzano' },
        { value: 'BS', label: 'Brescia' },
        { value: 'BR', label: 'Brindisi' },
        { value: 'CA', label: 'Cagliari' },
        { value: 'CL', label: 'Caltanissetta' },
        { value: 'CB', label: 'Campobasso' },
        { value: 'CI', label: 'Carbonia-Iglesias' },
        { value: 'CE', label: 'Caserta' },
        { value: 'CT', label: 'Catania' },
        { value: 'CZ', label: 'Catanzaro' },
        { value: 'CH', label: 'Chieti' },
        { value: 'CO', label: 'Como' },
        { value: 'CS', label: 'Cosenza' },
        { value: 'CR', label: 'Cremona' },
        { value: 'KR', label: 'Crotone' },
        { value: 'CN', label: 'Cuneo' },
        { value: 'EN', label: 'Enna' },
        { value: 'FM', label: 'Fermo' },
        { value: 'FE', label: 'Ferrara' },
        { value: 'FI', label: 'Florence' },
        { value: 'FG', label: 'Foggia' },
        { value: 'FC', label: 'Forlì-Cesena' },
        { value: 'FR', label: 'Frosinone' },
        { value: 'GE', label: 'Genoa' },
        { value: 'GO', label: 'Gorizia' },
        { value: 'GR', label: 'Grosseto' },
        { value: 'IM', label: 'Imperia' },
        { value: 'IS', label: 'Isernia' },
        { value: 'AQ', label: 'L\\\'Aquila' },
        { value: 'SP', label: 'La Spezia' },
        { value: 'LT', label: 'Latina' },
        { value: 'LE', label: 'Lecce' },
        { value: 'LC', label: 'Lecco' },
        { value: 'LI', label: 'Livorno' },
        { value: 'LO', label: 'Lodi' },
        { value: 'LU', label: 'Lucca' },
        { value: 'MC', label: 'Macerata' },
        { value: 'MN', label: 'Mantua' },
        { value: 'MS', label: 'Massa and Carrara' },
        { value: 'MT', label: 'Matera' },
        { value: 'VS', label: 'Medio Campidano' },
        { value: 'ME', label: 'Messina' },
        { value: 'MI', label: 'Milan' },
        { value: 'MO', label: 'Modena' },
        { value: 'MB', label: 'Monza and Brianza' },
        { value: 'NA', label: 'Naples' },
        { value: 'NO', label: 'Novara' },
        { value: 'NU', label: 'Nuoro' },
        { value: 'OG', label: 'Ogliastra' },
        { value: 'OT', label: 'Olbia-Tempio' },
        { value: 'OR', label: 'Oristano' },
        { value: 'PD', label: 'Padua' },
        { value: 'PA', label: 'Palermo' },
        { value: 'PR', label: 'Parma' },
        { value: 'PV', label: 'Pavia' },
        { value: 'PG', label: 'Perugia' },
        { value: 'PU', label: 'Pesaro and Urbino' },
        { value: 'PE', label: 'Pescara' },
        { value: 'PC', label: 'Piacenza' },
        { value: 'PI', label: 'Pisa' },
        { value: 'PT', label: 'Pistoia' },
        { value: 'PN', label: 'Pordenone' },
        { value: 'PZ', label: 'Potenza' },
        { value: 'PO', label: 'Prato' },
        { value: 'RG', label: 'Ragusa' },
        { value: 'RA', label: 'Ravenna' },
        { value: 'RC', label: 'Reggio Calabria' },
        { value: 'RE', label: 'Reggio Emilia' },
        { value: 'RI', label: 'Rieti' },
        { value: 'RN', label: 'Rimini' },
        { value: 'RM', label: 'Rome' },
        { value: 'RO', label: 'Rovigo' },
        { value: 'SA', label: 'Salerno' },
        { value: 'SS', label: 'Sassari' },
        { value: 'SV', label: 'Savona' },
        { value: 'SI', label: 'Siena' },
        { value: 'SO', label: 'Sondrio' },
        { value: 'SR', label: 'Syracuse' },
        { value: 'TA', label: 'Taranto' },
        { value: 'TE', label: 'Teramo' },
        { value: 'TR', label: 'Terni' },
        { value: 'TP', label: 'Trapani' },
        { value: 'TN', label: 'Trento' },
        { value: 'TV', label: 'Treviso' },
        { value: 'TS', label: 'Trieste' },
        { value: 'TO', label: 'Turin' },
        { value: 'UD', label: 'Udine' },
        { value: 'VA', label: 'Varese' },
        { value: 'VE', label: 'Venice' },
        { value: 'VB', label: 'Verbano-Cusio-Ossola' },
        { value: 'VC', label: 'Vercelli' },
        { value: 'VR', label: 'Verona' },
        { value: 'VV', label: 'Vibo Valentia' },
        { value: 'VI', label: 'Vicenza' },
        { value: 'VT', label: 'Viterbo' },
    ] as ComboboxOption[],
    'MX': [
        { value: 'AG', label: 'Aguascalientes' },
        { value: 'BC', label: 'Baja California' },
        { value: 'BS', label: 'Baja California Sur' },
        { value: 'CM', label: 'Campeche' },
        { value: 'CS', label: 'Chiapas' },
        { value: 'CH', label: 'Chihuahua' },
        { value: 'CO', label: 'Coahuila' },
        { value: 'CL', label: 'Colima' },
        { value: 'DG', label: 'Durango' },
        { value: 'DF', label: 'Federal District' },
        { value: 'GT', label: 'Guanajuato' },
        { value: 'GR', label: 'Guerrero' },
        { value: 'HG', label: 'Hidalgo' },
        { value: 'JA', label: 'Jalisco' },
        { value: 'ME', label: 'Mexico State' },
        { value: 'MI', label: 'Michoacán' },
        { value: 'MO', label: 'Morelos' },
        { value: 'NA', label: 'Nayarit' },
        { value: 'NL', label: 'Nuevo León' },
        { value: 'QA', label: 'Oaxaca' },
        { value: 'PB', label: 'Puebla' },
        { value: 'QE', label: 'Querétaro' },
        { value: 'QR', label: 'Quintana Roo' },
        { value: 'SL', label: 'San Luis Potosí' },
        { value: 'SI', label: 'Sinaloa' },
        { value: 'SO', label: 'Sonora' },
        { value: 'TB', label: 'Tabasco' },
        { value: 'TM', label: 'Tamaulipas' },
        { value: 'TL', label: 'Tlaxcala' },
        { value: 'VE', label: 'Veracruz' },
        { value: 'YU', label: 'Yucatán' },
        { value: 'ZA', label: 'Zacatecas' },
    ] as ComboboxOption[],
    'US': [
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'DC', label: 'District of Columbia' },
        { value: 'FL', label: 'Florida' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
        { value: 'AS', label: 'American Samoa' },
        { value: 'GU', label: 'Guam' },
        { value: 'MP', label: 'Northern Mariana Islands' },
        { value: 'PR', label: 'Puerto Rico' },
        { value: 'UM', label: 'U.S. Minor Outlying Islands' },
        { value: 'VI', label: 'U.S. Virgin Islands' },
        { value: 'AA', label: 'U.S. Armed Forces Americas' },
        { value: 'AE', label: 'U.S. Armed Forces Europe' },
        { value: 'AP', label: 'U.S. Armed Forces Pacific' },
    ] as ComboboxOption[],
}
