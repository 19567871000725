<template>
    <li class="ut-combobox-option" :class="optionClassNames" :data-value="value">
        {{ label }}
    </li>
</template>

<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
    name: 'ut-combobox-option',

    props: {
        /**
         * Indicates whether this option is focused.
         */
        isFocused: Boolean,

        /**
         * Indicates whether this option is selected.
         * @type {boolean}
         */
        isSelected: Boolean,

        /**
         * Option label.
         */
        label: String,

        /**
         * Option value.
         */
        value: String,
    },

    computed: {
        /**
         * CSS class names for the option.
         */
        optionClassNames(): string {
            let classNames = ''

            // Focus
            if (this.isFocused) classNames += ' ut-has-focus'

            // Selected
            if (this.isSelected) classNames += ' ut-is-selected'

            return classNames
        },
    },
})
</script>

<style scoped lang="scss">

.ut-combobox-option {
    cursor: pointer;
    padding: .5rem .75rem;

    &:hover {
        background: #f3f4f6;
        transition-duration: 150ms;
    }
}

.ut-has-focus {
    background: #E5E7EB;
}

.ut-is-selected {
    background: rgb(224 242 254) !important;
    color: rgb(8 47 73);
}

</style>
