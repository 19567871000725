import type { Appearance, CheckoutLocale, StripeElementLocale } from '@stripe/stripe-js'

export class RuntimeConfiguration {
    /**
     * Optional amount.
     */
    amount?: bigint

    /**
     * Appearance options.
     */
    appearance: Appearance

    /**
     * LinvioPay public key.
     */
    linvioPayPublicKey: string

    /**
     * Locale.
     */
    locale: StripeElementLocale | CheckoutLocale

    /**
     * Runtime mode.
     */
    mode: string

    /**
     * Optional payment ID.
     */
    paymentId?: string

    /**
     * Optional payment method ID.
     */
    paymentMethodId?: string

    /**
     * reCAPTCHA v3 site key.
     */
    recaptchaV3SiteKey?: string

    /**
     * Validation errors to be thrown when constructing object.
     */
    _validationErrors: Array<string> = []

    /**
     * Initializes a new instance of the RuntimeConfiguration class.
     * @param runtimeConfiguration Runtime configuration data.
     */
    constructor(runtimeConfiguration: Partial<RuntimeConfiguration>) {
        if (!runtimeConfiguration) throw new Error('No options provided.')

        // Validating and assigning data
        if (this._isValidAmount(runtimeConfiguration.amount, runtimeConfiguration.paymentId)) {
            this.amount = runtimeConfiguration.amount || BigInt(0)
        }

        this.appearance = runtimeConfiguration.appearance || {}

        if (this._isValidLocale(runtimeConfiguration.locale)) {
            this.locale = runtimeConfiguration.locale || 'auto'
        }

        if (this._isValidMode(runtimeConfiguration.mode)) {
            this.mode = runtimeConfiguration.mode || 'dev'
        }

        if (this._isValidPublicKey(runtimeConfiguration.linvioPayPublicKey)) {
            this.linvioPayPublicKey = runtimeConfiguration.linvioPayPublicKey!
        }

        this.paymentId = runtimeConfiguration.paymentId

        this.paymentMethodId = runtimeConfiguration.paymentMethodId

        this.recaptchaV3SiteKey = runtimeConfiguration.recaptchaV3SiteKey

        // Throwing validation errors if necessary
        if (this._validationErrors.length > 0) {
            throw new Error(`The runtime configuration has invalid values:\n${this._validationErrors.join('\n- ')}`)
        }
    }

    private _isValidAmount = (amount?: bigint, paymentId?: string): boolean => {
        let isValid: boolean = true

        if (amount && paymentId && paymentId.length > 0) {
            isValid = false
            this._validationErrors.push('Amount option not allowed when providing a paymentId.')
        }

        return isValid
    }

    private _isValidLocale = (locale?: string): boolean => {
        let isValid: boolean = true

        if (locale && locale.length > 0) {
            const validLocales: string[] = ['auto']
            isValid = validLocales.includes(locale)

            if (!isValid) {
                this._validationErrors.push(`Invalid locale: ${locale}. Expected values are ${validLocales.join(', ')}`)
            }
        }

        return isValid
    }

    private _isValidMode = (mode?: string): boolean => {
        let isValid: boolean = true

        if (mode && mode.length > 0) {
            const validModes: string[] = ['dev', 'test', 'prod']
            isValid = validModes.includes(mode)

            if (!isValid) {
                this._validationErrors.push(`Invalid mode: ${mode}. Expected values are ${validModes.join(', ')}`)
            }
        }

        return isValid
    }

    private _isValidPublicKey = (publicKey?: string): boolean => {
        let isValid: boolean = true

        if (!publicKey || publicKey.length === 0) {
            isValid = false
            this._validationErrors.push('No LinvioPay Public Key provided.')
        } else {
            const regexPattern: RegExp = /([a-zA-Z]+)_([a-zA-Z]+)/
            const match = publicKey.match(regexPattern)

            if (match) {
                const prefixValue = match[1]
                const modeValue = match[2]

                if (prefixValue !== 'ck') {
                    this._validationErrors.push('The provided LinvioPay Public Key does not start with "ck_", please make sure to use the correct public key.')
                }

                if (modeValue !== this.mode){
                    this._validationErrors.push(`The provided LinvioPay Public Key cannot be used in ${this.mode} mode.`)
                }
            } else {
                this._validationErrors.push('The provided LinvioPay Public Key has an invalid format.')
            }
        }

        return isValid
    }
}
