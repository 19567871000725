export const API_MODE = {
    DEV: 'dev',
    TEST: 'test',
    PROD: 'prod',
}

export const API_URL = {
    DEV: `${import.meta.env.VITE_LINVIOPAY_V2_DEV_URL}`,
    TEST: `${import.meta.env.VITE_LINVIOPAY_V2_TEST_URL}`,
    PROD: `${import.meta.env.VITE_LINVIOPAY_V2_PROD_URL}`,
}
