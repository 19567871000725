<template>
    <transition appear name="fade">
        <div role="status" class="ut-stencil-panel animate-pulse w-full" data-testid="stencilPanel">

            <!-- Amount -->
            <div class="flex items-center justify-between">
                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-12 bg-gray-200 rounded"/>
                </div>
            </div>

            <!-- Payment methods -->
            <div class="flex items-center justify-between mt-4">
                <div class="w-full h-16 mt-1.5 bg-gray-200 rounded"/>
            </div>

            <!-- Card number -->
            <div class="flex items-center justify-between">
                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>
            </div>

            <!-- Validity / cvc -->
            <div class="flex items-center justify-between gap-x-3">

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

            </div>

            <!-- Save payment method checkbox -->
            <div class="flex items-center justify-between">
                <div class="h-4 bg-gray-300 rounded-full w-48 mt-9 mb-7"/>
            </div>

            <!-- First name / Last name -->
            <div class="flex items-center justify-between gap-x-3">

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

            </div>

            <!-- Address / City -->
            <div class="flex items-center justify-between gap-x-3 mt-1">

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

            </div>

            <!-- Country / State -->
            <div class="flex items-center justify-between gap-x-3 mt-1.5">

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

            </div>

            <!-- Postal code / email -->
            <div class="flex items-center justify-between gap-x-3 mt-1.5">

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

                <div class="w-full">
                    <div class="h-3 bg-gray-300 rounded-full w-24 mt-3 mb-2"/>
                    <div class="w-full h-11 bg-gray-200 rounded"/>
                </div>

            </div>

            <!-- Submit button -->
            <div class="flex items-center justify-between">
                <div class="h-11 w-full bg-gray-300 rounded mt-6"/>
            </div>

            <span class="sr-only">Loading...</span>

        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ut-stencil-panel',
})
</script>

<style scoped lang="scss">

.ut-stencil-panel {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    color: #6d6e78;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1.5rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
}

</style>
