export const ERROR_TYPE = {
    VALUE_ERROR: 'value_error',
}

export const ERROR_PARAMETER = {
    BILLING_CITY: 'billing_city',
    BILLING_COUNTRY: 'billing_country',
    BILLING_EMAIL: 'billing_email',
    BILLING_FIRST_NAME: 'billing_first_name',
    BILLING_LAST_NAME: 'billing_last_name',
    BILLING_POSTAL_CODE: 'billing_postal_code',
    BILLING_STATE: 'billing_state',
    BILLING_STREET: 'billing_street',
}
