<template>
    <ut-form-element :errors="errors" v-bind="formElementAttributes">
        <label class="ut-checkbox">

            <!-- Input -->
            <input
                type="checkbox"
                class="ut-input"
                :checked="modelValue"
                v-bind="inputAttributes"
                @change="handleChange"
            >

            <!-- Label -->
            <span class="ut-label">
                {{ label }}
            </span>

        </label>
    </ut-form-element>
</template>

<script lang="ts">
import UtFormElement from '@/components/ut-form-element/ut-form-element.vue'
import { defineComponent, type PropType } from 'vue'
import type { ValidationError } from '@/components/ut-form-element/validation-error'
import { EVENTS } from '@/constants'

export default defineComponent({
    name: 'ut-checkbox',

    components: { UtFormElement },

    inheritAttrs: false,

    props: {
        /**
         * Array of error objects from vuelidate.
         */
        errors: { type: Array as PropType<ValidationError[]>, default: () => [] as ValidationError[] },

        /**
         * Checkbox label.
         */
        label: String,

        /**
         * Checkbox value.
         */
        modelValue: { type: Boolean, required: false },
    },

    computed: {
        /**
         * Bindable form element attributes.
         */
        formElementAttributes(): Record<string, unknown> {
            const attributes: Record<string, unknown> = {}

            for (const attribute in this.$attrs) {
                if (attribute.startsWith('data-') || attribute === 'class') {
                    attributes[attribute] = this.$attrs[attribute]
                }
            }

            return attributes
        },

        /**
         * Bindable input attributes.
         */
        inputAttributes(): Record<string, unknown> {
            const attributes: Record<string, unknown> = {}

            for (const attribute in this.$attrs) {
                if (!attribute.startsWith('data-') && attribute !== 'class') {
                    attributes[attribute] = this.$attrs[attribute]
                }
            }

            return attributes
        },
    },

    methods: {
        /**
         * Handles the change event on the input.
         * @param event The fired event.
         */
        handleChange(event: Event): void {
            const target = event.target as HTMLInputElement
            this.$emit(EVENTS.UPDATE_MODEL_VALUE, target.checked)
        },
    },
})
</script>

<style scoped lang="scss">

.ut-checkbox {
    align-items: center;
    cursor: pointer;
    display: inline-flex;

    .ut-input {
        border: 1px solid #e6e6e6;
        border-radius: 0.25rem;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
        color: #0570de;
        transition: all 150ms ease-in-out;

        &:focus {
            border-color: hsla(210, 96%, 45%, 50%);
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%) , 0 1px 1px 0 rgba(0, 0, 0, 0.08);
            outline: 0;
        }
    }

    .ut-label {
        margin-left: 0.5rem;
    }
}

</style>
