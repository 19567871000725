<template>
    <transition name="fade">
        <div v-if="store.showMandate" class="ut-mandate-panel" data-testid="mandatePanel">

            <div class="ut-mandate-modal-container">
                <div class="ut-mandate-modal text-center text-slate-800 mt-4">

                    <p class="m-4">
                        By clicking Accept, you authorize LinvioPay to debit the bank account specified above for any
                        amount owed for charges arising from your use of LinvioPay’s services and/or purchase of
                        products from LinvioPay, pursuant to LinvioPay’s website and terms, until this authorization is
                        revoked. You may amend or cancel this authorization at any time by providing notice to LinvioPay
                        with 30 (thirty) days notice.
                    </p>

                    <p class="m-4">
                        If you use LinvioPay’s services or purchase additional products periodically pursuant to
                        LinvioPay’s terms, you authorize LinvioPay to debit your bank account periodically. Payments
                        that fall outside of the regular debits authorized above will only be debited after your
                        authorization is obtained.
                    </p>

                    <ut-button
                        class="mt-4"
                        data-testid="mandateAcceptButton"
                        label="Accept"
                        @click="handleClickAccept"
                    />

                    <ut-button
                        class="mt-4"
                        data-testid="mandateCancelButton"
                        label="Cancel"
                        secondary
                        @click="handleClickCancel"
                    />

                </div>
            </div>

            <div class="ut-mandate-backdrop"/>

        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store/store'
import UtButton from '@/components/ut-button/ut-button.vue'
import { EVENTS } from '@/constants'

export default defineComponent({
    name: 'ut-mandate-panel',

    components: {
        UtButton,
    },

    computed: {
        /**
         * Application store.
         */
        store() {
            return useStore()
        },
    },

    methods: {
        /**
         * Handles the click event on the accept button.
         */
        handleClickAccept(): void {
            this.$emit(EVENTS.ACCEPT)
            this.store.showMandate = false
        },

        /**
         * Handles the click event on the cancel button.
         */
        handleClickCancel(): void {
            this.$emit(EVENTS.CANCEL)
            this.store.showMandate = false
        },
    },
})
</script>

<style scoped lang="scss">

.ut-mandate-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(8, 7, 7, .65);
    z-index: 9000;
}

.ut-mandate-modal {
    background-color: white;
    border-radius: 1.25rem;
    box-shadow: rgba(0, 0, 0, 0) 0 5px 15px 0, rgba(48, 49, 61, 0) 0 15px 35px 0, rgba(48, 49, 61, 0) 0 50px 100px 0;
    padding: 1.5rem;
    position: relative;
    z-index: 9002;
}

.ut-mandate-modal-container {
    margin: 0 auto;
    max-width: 40rem;
    min-width: 20rem;
    padding: 3rem 0 3rem;
    width: 50%;
}

.ut-mandate-panel {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9001;
}

</style>
