<template>
    <div class="ut-form-element" :class="{'ut-has-error': hasError}">

        <!-- Label -->
        <label v-if="label" class="ut-label">
            {{ label }}
        </label>

        <!-- Control -->
        <slot/>

        <!-- Error messages -->
        <div v-if="errors?.length > 0" class="ut-errors" data-testid="errorMessage">
            <p v-for="error of errors" :key="error.$uid">
                {{ error.$message }}.
            </p>
        </div>

        <!-- External error messages -->
        <div v-if="externalErrors?.length > 0" class="ut-errors" data-testid="externalErrorMessage">
            <p v-for="(error, index) of externalErrors" :key="index">
                {{ error.message }}
            </p>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { ValidationError } from '@/components/ut-form-element/validation-error'
import type { ExternalError } from '@/components/ut-form-element/external-error'

export default defineComponent({
    name: 'ut-form-element',

    props: {
        /**
         * Array of error objects from vuelidate.
         */
        errors: {
            type: Array as PropType<ValidationError[]>,
            default: () => [] as ValidationError[],
        },

        /**
         * Array of external error objects.
         */
        externalErrors: {
            type: Array as PropType<ExternalError[]>,
            default: () => [] as ExternalError[],
        },

        /**
         * Input label.
         */
        label: String,
    },

    computed: {
        hasError(): boolean {
            return Boolean(this.errors?.length > 0 || this.externalErrors?.length > 0)
        },
    },
})
</script>

<style scoped lang="scss">

.ut-errors {
    color: #df1b41;
    font-size: 0.93rem;
}

.ut-form-element {
    display: block;
    position: relative;
}

.ut-label {
    color: #30313d;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-size: 0.93rem;
    line-height: 1.25rem;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

</style>
