<template>
    <div role="status" class="ut-error-panel w-full" data-testid="errorPanel">

        <h1>
            Something went wrong
        </h1>

        <p>
            You might be having a network connection problem, or the payment provider cannot be reached at the moment.
        </p>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ut-error-panel',
})
</script>

<style scoped lang="scss">

.ut-error-panel {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
    color: #6d6e78;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1.5rem;
    text-align: center;

    h1 {
        font-size: 1rem;
        line-height: 2rem;
    }
}

</style>
