<template>
    <div class="ut-radio-section" :class="{'is-bare': !store.hasStoredPaymentMethods}">

        <!-- Header -->
        <div
            v-if="store.hasStoredPaymentMethods"
            class="ut-radio-section-header"
            :class="{'is-selected': selected}"
            data-testid="header"
            tabindex="0"
            @click="handleClickHeader"
            @keyup.enter.stop="handleClickHeader"
        >

            <!-- Selected radio button -->
            <svg
                v-if="selected"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="ut-radio-section-button is-selected"
                focusable="false"
                role="presentation"
            >
                <path
                    d="M14.25 8C14.25 11.4518 11.4518 14.25 8 14.25C4.54822 14.25 1.75 11.4518 1.75 8C1.75 4.54822 4.54822 1.75 8 1.75C11.4518 1.75 14.25 4.54822 14.25 8Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    fill="none"
                />
                <circle cx="8" cy="8" r="3.5"/>
            </svg>

            <!-- Unselected radio button -->
            <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                class="ut-radio-section-button"
                focusable="false"
                role="presentation"
            >
                <path
                    d="M14.25 8C14.25 11.4518 11.4518 14.25 8 14.25C4.54822 14.25 1.75 11.4518 1.75 8C1.75 4.54822 4.54822 1.75 8 1.75C11.4518 1.75 14.25 4.54822 14.25 8Z"
                    stroke="currentColor"
                    stroke-width="1.5"
                    fill="none"
                />
            </svg>

            <!-- Label -->
            <p class="ut-radio-section-label">
                {{ label }}
            </p>

        </div>

        <!-- Body -->
        <transition
            appear
            @before-enter="collapse"
            @enter="expand"
            @after-enter="resetHeight"
            @before-leave="expand"
            @leave="collapse"
        >
            <div v-show="selected || !store.hasStoredPaymentMethods" class="ut-radio-section-body">
                <slot/>
            </div>
        </transition>

    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { EVENTS } from '@/constants'
import { useStore } from '@/store/store'

export default defineComponent({
    name: 'ut-radio-section',

    props: {
        /**
         * Indicates whether this section is selected.
         */
        selected: Boolean,

        /**
         * Section label.
         */
        label: String,
    },

    computed: {
        /**
         * Application store.
         */
        store() {
            return useStore()
        },
    },

    methods: {
        /**
         * Smoothly collapses the body.
         * @param element Transition HTML element.
         */
        collapse(element: Element): void {
            const htmlElement = element as HTMLElement
            htmlElement.style.overflow = ''
            htmlElement.style.height = '0'
            htmlElement.style.opacity = '0'
        },

        /**
         * Smoothly expands the body.
         * @param element Transition HTML element.
         */
        expand(element: Element): void {
            const htmlElement = element as HTMLElement
            htmlElement.style.overflow = ''
            htmlElement.style.height = element.scrollHeight + 'px'
            htmlElement.scrollHeight
            htmlElement.style.opacity = '1'
        },

        /**
         * Smoothly resets the body height.
         * @param element Transition HTML element.
         */
        resetHeight(element: Element): void {
            const htmlElement = element as HTMLElement
            htmlElement.style.height = ''
            htmlElement.style.overflow = 'initial'
        },

        /**
         * Handles the click event on the header.
         */
        handleClickHeader() {
            if (!this.selected) {
                this.$emit(EVENTS.SELECT)
            }
        },
    },
})
</script>

<style scoped lang="scss">

.ut-radio-section {
    border-bottom: 1px solid #e6e6e6;
    padding: 1.5rem;

    &.is-bare {
        border-bottom: none;
        padding: 0 1.5rem;

        .ut-radio-section-body {
            margin-top: 0;
        }
    }

    &-header {
        align-items: center;
        color: rgb(109, 110, 120);
        cursor: pointer;
        display: flex;
        height: 1.5rem;
        transition: all 400ms ease;

        &.is-selected {
            color: rgb(5, 112, 222);
            margin-bottom: .75rem;
        }
    }

    &-label {
        -webkit-font-smoothing: antialiased;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.5rem;
    }

    &-button {
        height: 1.2rem;
        margin-inline-end: .75rem;
        width: 1.2rem;
    }

    &-body {
        overflow: hidden;
        transition: all 400ms ease;
    }
}

</style>
