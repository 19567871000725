<template>
    <button :class="buttonClassNames" type="button" @click.capture="handleClick">

        <!-- Spinner -->
        <svg
            v-show="showSpinner"
            class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            data-testid="spinner"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
            />
            <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
        </svg>

        <!-- Label -->
        {{ label }}

    </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ut-button',

    props: {
        /**
         * Button label.
         * When using the default slot this prop is ignored.
         */
        label: String,

        /**
         * Indicates whether this is a secondary styled button.
         */
        secondary: Boolean,

        /**
         * Indicates whether the button is showing its spinner.
         */
        showSpinner: Boolean,
    },

    computed: {
        /**
         * The CSS class names for the button element.
         */
        buttonClassNames(): string {
            let classNames = 'ut-button'

            // Color
            if (this.secondary) classNames += ' ut-button-secondary'
            else classNames += ' ut-button-primary'

            return classNames
        },
    },

    methods: {
        /**
         * Handles the click event on the button.
         * @param event The fired event.
         */
        handleClick(event: Event): void {
            if (this.showSpinner) {
                event.stopPropagation()
            }
        },
    },
})
</script>

<style scoped lang="scss">

.ut-button {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    align-items: center;
    border-radius: 0.5rem;
    display: inline-flex;
    font-size: 0.875rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1.25rem;
    padding: .75rem 1rem;
    transition: all 150ms ease-in-out;
    width: 100%;

    &-primary {
        background-color: #0570de;
        color: white;

        &:hover {
            background-color: rgb(29 78 216);
        }
    }

    &-secondary {
        background-color: white;
        border: 1px solid #e6e6e6;

        &:hover {
            background-color: #f7f7f7;
        }
    }
}

</style>
