<template>
    <transition name="fade">
        <div v-if="store.showResult" :class="resultPanelClassNames" data-testid="resultPanel">

            <!-- Completed result -->
            <div v-if="status == 'Completed'" class="resultContainer">

                <ut-icon name="success" large green/>

                <div class="text-center text-slate-800 mt-4">
                    {{ label }}
                </div>

            </div>

            <!-- Pending result -->
            <div v-else-if="status == 'Pending'" class="resultContainer">

                <ut-icon name="pending" large blue/>

                <div class="text-center text-slate-800 mt-4">
                    Payment Pending
                </div>

            </div>

        </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/store/store'
import UtIcon from '@/components/ut-icon/ut-icon.vue'

export default defineComponent({
    name: 'ut-result-panel',

    components: { UtIcon },

    computed: {
        /**
         * Result label.
         */
        label(): string {
            return (useStore().isInCapturePaymentMode)
                ? 'Payment Successfully Processed'
                : 'Payment Method Successfully Added'
        },

        /**
         * The CSS class names for the result panel element.
         */
        resultPanelClassNames(): string {
            let classNames = 'ut-result-panel'

            // Visibility
            if (!this.store.showResult) classNames += ' ut-invisible'

            return classNames
        },

        /**
         * Payment result status.
         */
        status(): string {
            return useStore().status
        },

        /**
         * Application store.
         */
        store() {
            return useStore()
        },
    },
})
</script>

<style scoped lang="scss">

.ut-result-panel {
    @apply absolute flex flex-col items-center justify-center z-50;
}
.resultContainer {
    @apply w-96 flex flex-col items-center justify-center p-5;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
}

</style>
