import Axios from 'axios'
import { useStore } from '@/store/store'
import { API_MODE, API_URL } from '@/constants'

import type { AxiosInstance, AxiosResponse } from 'axios'

let apiClient: AxiosInstance

const initializeApiClient = (): void => {
    const store = useStore()

    apiClient = Axios.create({
        baseURL: parseBaseUrl(store.mode),
        headers: {
            'Accept': 'application/json',
        },
    })

    // Add  auth token to header
    apiClient.defaults.headers.common['Authorization'] = store.runtimeConfiguration.linvioPayPublicKey

    // Add log interceptors when in development mode
    if (store.mode == API_MODE.DEV) {
        apiClient.interceptors.response.use(
            (response: AxiosResponse<any, any>) => {
                console.info(`[API Response] => ${response.config.method?.toUpperCase()} ${response.config.url}`, response)
                return response
            },
            error => {
                console.error(`[API Response] => ${error.response.config.method.toUpperCase()} ${error.response.config.url}`, error.response)
                return error.response
            },
        )
    }
}

const parseBaseUrl = (mode: string): string => {
    if (mode == API_MODE.DEV) return API_URL.DEV
    if (mode == API_MODE.TEST) return API_URL.TEST
    if (mode == API_MODE.PROD) return API_URL.PROD

    throw new Error(`Invalid mode for Universal Terminal: ${mode}`)
}

export { apiClient, initializeApiClient }
