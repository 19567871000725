export const PAYMENT_METHOD_TYPE = {
    /**
     * Pre-authorized debit payments are used to debit Canadian bank accounts through the Automated Clearing Settlement System (ACSS).
     */
    ACSS_DEBIT: 'acss_debit',

    /**
     * Affirm is a buy now, pay later payment method in the US.
     */
    AFFIRM: 'affirm',

    /**
     * Afterpay / Clearpay is a buy now, pay later payment method used in Australia, Canada, France, New Zealand, Spain, the UK, and the US.
     */
    AFTERPAY_CLEARPAY: 'afterpay_clearpay',

    /**
     * Alipay is a digital wallet payment method used in China.
     */
    ALIPAY: 'alipay',

    /**
     * BECS Direct Debit is used to debit Australian bank accounts through the Bulk Electronic Clearing System (BECS).
     */
    AU_BECS_DEBIT: 'au_becs_debit',

    /**
     * Bacs Direct Debit is used to debit UK bank accounts.
     */
    BACS_DEBIT: 'bacs_debit',

    /**
     * Bancontact is a bank redirect payment method used in Belgium.
     */
    BANCONTACT: 'bancontact',

    /**
     * BLIK is a single-use payment method common in Poland.
     */
    BLIK: 'blik',

    /**
     * Boleto is a voucher-based payment method used in Brazil.
     */
    BOLETO: 'boleto',

    /**
     * Card payments are supported through many networks and card brands.
     */
    CARD: 'card',

    /**
     * Stripe Terminal is used to collect in-person card payments.
     */
    CARD_PRESENT: 'card_present',

    /**
     * Cash App Pay enables customers to frictionlessly authenticate payments in the Cash App using their stored balance or linked card.
     */
    CASHAPP: 'cashapp',

    /**
     * Uses a customer’s cash balance for the payment.
     */
    CUSTOMER_BALANCE: 'customer_balance',

    /**
     * EPS is an Austria-based bank redirect payment method.
     */
    EPS: 'eps',

    /**
     * FPX is a Malaysia-based bank redirect payment method.
     */
    FPX: 'fpx',

    /**
     * giropay is a German bank redirect payment method.
     */
    GIROPAY: 'giropay',

    /**
     * GrabPay is a digital wallet payment method used in Southeast Asia.
     */
    GRABPAY: 'grabpay',

    /**
     * iDEAL is a Netherlands-based bank redirect payment method.
     */
    IDEAL: 'ideal',

    /**
     * Stripe Terminal accepts Interac debit cards for in-person payments in Canada.
     */
    INTERAC_PRESENT: 'interac_present',

    /**
     * Klarna is a global buy now, pay later payment method.
     */
    KLARNA: 'klarna',

    /**
     * Konbini is a cash-based voucher payment method used in Japan.
     */
    KONBINI: 'konbini',

    /**
     * Link allows customers to pay with their saved payment details.
     */
    LINK: 'link',

    /**
     * OXXO is a cash-based voucher payment method used in Mexico.
     */
    OXXO: 'oxxo',

    /**
     * Przelewy24 is a bank redirect payment method used in Poland.
     */
    P24: 'p24',

    /**
     * PayNow is a QR code payment method used in Singapore.
     */
    PAYNOW: 'paynow',

    /**
     * PayPal is an online wallet and redirect payment method commonly used in Europe.
     */
    PAYPAL: 'paypal',

    /**
     * Pix is an instant bank transfer payment method in Brazil.
     */
    PIX: 'pix',

    /**
     * PromptPay is an instant funds transfer service popular in Thailand.
     */
    PROMPTPAY: 'promptpay',

    /**
     * Revolut Pay is a digital wallet payment method used in the United Kingdom.
     */
    REVOLUT_PAY: 'revolut_pay',

    /**
     * SEPA Direct Debit is used to debit bank accounts within the Single Euro Payments Area (SEPA) region.
     */
    SEPA_DEBIT: 'sepa_debit',

    /**
     * Sofort is a bank redirect payment method used in Europe.
     */
    SOFORT: 'sofort',

    /**
     * ACH Direct Debit is used to debit US bank accounts through the Automated Clearing House (ACH) payments system.
     */
    US_BANK_ACCOUNT: 'us_bank_account',

    /**
     * WeChat Pay is a digital wallet payment method based in China.
     */
    WECHAT_PAY: 'wechat_pay',

    /**
     * Zip is a Buy now, pay later Payment Method.
     */
    ZIP: 'zip',
}

export const CARD_BRAND = {
    AMEX: 'amex',
    DINERS: 'diners',
    DISCOVER: 'discover',
    EFTPOS_AU: 'eftpos_au',
    JCB: 'jcb',
    MASTERCARD: 'mastercard',
    UNIONPAY: 'unionpay',
    VISA: 'visa',
    UNKNOWN: 'unknown',
}
