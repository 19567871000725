<template>
    <span class="ut-icon" :class="iconClassNames">

        <!-- Bank -->
        <svg
            v-if="name === 'bank'"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="#D8DEE4"
                d="M0 0h32v32H0z"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.592 5.093a.938.938 0 0 1 .816 0l8.885 4.29c.433.209.707.646.707 1.126V12.5c0 .69-.56 1.25-1.25 1.25h-1.875v5.983l2.434 1.217c.424.212.691.645.691 1.118v1.682c0 .69-.56 1.25-1.25 1.25H7.25C6.56 25 6 24.44 6 23.75v-1.68c0-.476.27-.91.696-1.122l2.462-1.216-.028-5.982H7.25c-.69 0-1.25-.56-1.25-1.25v-1.991c0-.48.275-.917.707-1.126l8.885-4.29ZM21 20.313V13.75h-1.875v6.563a.937.937 0 1 1-1.875 0V13.75h-2.5v6.563a.937.937 0 1 1-1.875 0V13.75h-1.87l.031 6.558a.937.937 0 0 1-.522.845l-2.639 1.304v.668h16.25v-.67l-2.607-1.304a.937.937 0 0 1-.518-.838Zm3.125-8.438v-.974L16 6.979 7.875 10.9v.974h16.25Z"
                fill="#474E5A"
            />
        </svg>

        <!-- Chevron down -->
        <svg
            v-else-if="name === 'chevron-down'"
            viewBox="0 0 12 12"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z"
            />
        </svg>

        <!-- Pending -->
        <svg
            v-else-if="name === 'pending'"
            fill="none"
            stroke-width="1.5"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M20.942 13.021a9 9 0 1 0 -9.407 7.967"/>
            <path d="M12 7v5l3 3"/>
            <path d="M15 19l2 2l4 -4"/>
        </svg>

        <!-- Mastercard -->
        <svg
            v-else-if="name === 'mastercard'"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none" fill-rule="evenodd">
                <path
                    d="M0 0h32v32H0z"
                    fill="#000"
                />
                <g fill-rule="nonzero">
                    <path
                        d="M13.02 10.505h5.923v10.857H13.02z"
                        fill="#ff5f00"
                    />
                    <path
                        d="M13.396 15.935a6.944 6.944 0 0 1 2.585-5.43c-2.775-2.224-6.76-1.9-9.156.745s-2.395 6.723 0 9.368 6.38 2.969 9.156.744a6.944 6.944 0 0 1-2.585-5.427z"
                        fill="#eb001b"
                    />
                    <path
                        d="M26.934 15.935c0 2.643-1.48 5.054-3.81 6.21s-5.105.851-7.143-.783a6.955 6.955 0 0 0 2.587-5.428c0-2.118-.954-4.12-2.587-5.429 2.038-1.633 4.81-1.937 7.142-.782s3.811 3.566 3.811 6.21z"
                        fill="#f79e1b"
                    />
                </g>
            </g>
        </svg>

        <!-- Success -->
        <svg
            v-else-if="name === 'success'"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                stroke-width="0"
                fill="currentColor"
            />
        </svg>

        <!-- Visa -->
        <svg
            v-else-if="name === 'visa'"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g fill="none" fill-rule="evenodd">
                <path d="M0 0h32v32H0z" fill="#00579f"/>
                <g fill="#fff" fill-rule="nonzero">
                    <path
                        d="M13.823 19.876H11.8l1.265-7.736h2.023zm7.334-7.546a5.036 5.036 0 0 0-1.814-.33c-1.998 0-3.405 1.053-3.414 2.56-.016 1.11 1.007 1.728 1.773 2.098.783.379 1.05.626 1.05.963-.009.518-.633.757-1.216.757-.808 0-1.24-.123-1.898-.411l-.267-.124-.283 1.737c.475.213 1.349.403 2.257.411 2.123 0 3.505-1.037 3.521-2.641.008-.881-.532-1.556-1.698-2.107-.708-.354-1.141-.593-1.141-.955.008-.33.366-.667 1.165-.667a3.471 3.471 0 0 1 1.507.297l.183.082zm2.69 4.806.807-2.165c-.008.017.167-.452.266-.74l.142.666s.383 1.852.466 2.239h-1.682zm2.497-4.996h-1.565c-.483 0-.85.14-1.058.642l-3.005 7.094h2.123l.425-1.16h2.597c.059.271.242 1.16.242 1.16h1.873zm-16.234 0-1.982 5.275-.216-1.07c-.366-1.234-1.515-2.575-2.797-3.242l1.815 6.765h2.14l3.18-7.728z"
                    />
                    <path
                        d="M6.289 12.14H3.033L3 12.297c2.54.641 4.221 2.189 4.912 4.049l-.708-3.556c-.116-.494-.474-.633-.915-.65z"
                    />
                </g>
            </g>
        </svg>

    </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'ut-icon',

    props: {
        /**
         * Indicates whether the icon uses the blue color.
         */
        blue: Boolean,

        /**
         * Indicates whether the icon uses the green color.
         */
        green: Boolean,

        /**
         * Icon name.
         */
        name: String,

        /**
         * Indicates whether the icon uses the large size.
         */
        large: Boolean,

        /**
         * Indicates whether the icon uses the small size.
         */
        small: Boolean,
    },

    computed: {
        /**
         * The CSS class names for the icon element.
         */
        iconClassNames(): string {
            let classNames = ''

            // Color
            if (this.blue) classNames += ' ut-icon_blue'
            else if (this.green) classNames += ' ut-icon_green'
            else classNames += ' ut-icon_default'

            // Size
            if (this.large) classNames += ' ut-icon_large'
            else if (this.small) classNames += ' ut-icon_small'
            else classNames += ' ut-icon_medium'

            return classNames
        },
    },
})
</script>

<style scoped lang="scss">

.ut-icon {
    display: inline;

    &_blue {
        color: #0369A1;
    }

    &_green {
        color: #15803D;
    }

    &_default {
        color: #6d6e78;
    }

    &_large {
        svg {
            height: 5rem;
            width: 5rem;
        }
    }

    &_medium {
        svg {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    &_small {
        svg {
            height: .8rem;
            width: .8rem;
        }
    }
}

</style>
